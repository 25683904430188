import React from "react";

function ProgressBar(props) {
  const { textColor, bgcolor, completed } = props;

  const wrapperStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "-20px",
  };

  const containerStyles = {
    display: "flex",
    height: 10,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: "25px",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const percentageStyles = {
    paddingLeft: "20px",
    color: `${textColor}`,
    fontWeight: "bold",
    fontSize: "20px",
  };

  return (
    <div id="ProgressBar" className="ProgressBar" data-testid="ProgressBar">
      <div style={wrapperStyles}>
        <div style={containerStyles}>
          <div style={fillerStyles}></div>
        </div>
        <span style={percentageStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
}

export default ProgressBar;
