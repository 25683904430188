import { getMaximoConfig } from "../../../../services/index";

async function maxappsHostVerification(mockgetconfig, mockwindow) {
  try {
    const configFunction = mockgetconfig ? mockgetconfig : getMaximoConfig;
    const res = await configFunction();
    const { protocol = "", address = "" } = res?.content?.server;
    const windowObj = mockwindow ? mockwindow : window;
    const {
      hostname: windowHostname,
      port: windowPort,
      protocol: windwoProtocol,
    } = windowObj.location;

    return !address || !protocol
      ? windowPort
        ? windwoProtocol.concat("//", windowHostname, ":", windowPort)
        : windwoProtocol.concat("//", windowHostname)
      : protocol.concat("://", address);
  } catch (error) {
    // alert("Error in maxapps host verification", error);
    console.error("Error in maxappsHost Verification", error);
  }
}

export default maxappsHostVerification;
