import "./DetailsListHeader.scss";

import React from "react";

import Icon from "../../../../../designer/components/icon";

function DetailsListHeader(props) {
  const { action = () => {}, title = "", icon = "" } = props;

  return (
    <div className="DetailsListHeader" data-testid="DetailsListHeader">
      <div
        className="details-list-header-title"
        data-testid="details-list-header-title"
      >
        <p>{title}</p>
      </div>
      <div className="show-details-button">
        <p>Show details</p>
        <button
          className="show-error-details-btn"
          data-testid="details-list-header-btn"
          onClick={action}
        >
          <Icon icon={icon} size={20} color="#6f53a3" />
        </button>
      </div>
    </div>
  );
}

export default DetailsListHeader;
