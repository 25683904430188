const systembugList = {
  api1: [
    {
      date: "1/09/2022, 3:06:35PM",
      description: "I'll be in you neighborhood",
      service: "api-AXIOS",
    },
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api11 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api111 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api1111 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
  ],
  api2: [
    {
      date: "1/09/2022, 3:06:35PM",
      description: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
      service: "api-AXIOS",
    },
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api11 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api111 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api1111 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
  ],
  api3: [
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "api3 I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
  ],
  api4: [
    {
      date: "1/09/2022, 3:06:35PM",
      description:
        "I'll be in your neighborhood doing errands thisI'll be in you neighborhood doing errands thisI'll be in your neighborhood doing in your neighborhood doing errands this…",
      service: "api-AXIOS",
    },
  ],
};
const collectionlist = [
  {
    name: "Accordion Item #1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
    source: "user",
    date: "01:00 - 12/02/2021",
    subentity: [
      {
        name: "Accordion Item #3",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
        source: "user/server",
        date: "17:39 - 12/02/2020",
        icon: "descending",
      },
      {
        name: "Accordion Item #3",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
        source: "user/server",
        date: "17:39 - 12/02/2020",
        icon: "descending",
        subentity: [
          {
            name: "Accordion Item #3",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
            source: "user/server",
            date: "17:39 - 12/02/2020",
            icon: "descending",
          },
        ],
      },
    ],
    icon: "descending",
  },
  {
    name: "Accordion Item #3",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
    source: "user/server",
    date: "17:39 - 12/02/2020",
    icon: "descending",
  },
];

// const mockData = {
//   systembugList,
//   collectionData
// }
export { systembugList, collectionlist };
