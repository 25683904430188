import React, { useState } from "react";

import IconsListing from "./IconsListing/IconsListing";
import ImageUpload from "./imageUpload.js/ImageUpload";

function CustomIcons() {
  const [iconsListing, setIconsListing] = useState([]);

  return (
    <div>
      <ImageUpload setIconsListing={setIconsListing} />
      <IconsListing
        iconsListing={iconsListing}
        setIconsListing={setIconsListing}
      />
    </div>
  );
}

export default CustomIcons;
