import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "../services";

async function deleteAllIssuesData() {
  try {
    const transactionNodeService = services?.NODE_ENDPOINT_DELETE_ALL_ISSUES;

    const res = await axiosProviderNode.post(transactionNodeService);
    return res?.data;
  } catch (error) {
    throw error;
  }
}

export default deleteAllIssuesData;
