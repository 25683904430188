import React, { useState } from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";

import { useStore } from "react-redux";

import ConnectButton from "../../signIn/connect-button/connectButton";

import logo from "../../../../../assets/images/logo.png";

import { dachboardSignUp } from "../../../services/index";

function SignUpForm() {
  const [loginMessage, setLoginMessage] = useState("");

  let store = useStore();

  const updateLoginStatus = () => {
    store.dispatch({
      type: "dashboard/updateLoginStatus",
      payload: {
        isLoggedin: true,
      },
    });
  };

  const goInstaller = () => {
    store.dispatch({
      type: "checkMaximoConfig",
    });
  };

  return (
    <div id="SignUpForm" className="">
      <div className="text-center py-5">
        <img className="img-fluid" src={logo} alt="logoImg" />
      </div>
      <Formik
        initialValues={{
          username: "",
          password: "",
          ConfirmPassword: "",
        }}
        onSubmit={(values, isSubmitting) => {
          setLoginMessage("");
          dachboardSignUp(
            values.username,
            values.password,
            values.ConfirmPassword
          )
            .then((response) => {
              if (response?.success) {
                updateLoginStatus();
                goInstaller();
              } else {
                setLoginMessage(
                  response?.message?.message ?? response?.message
                );
              }
            })
            .catch((error) => {
              setLoginMessage(error?.message);
            });
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <div className="form-body px-3">
            <Form>
              <div className="form-group">
                <Field
                  data-testid="username"
                  type="text"
                  name="username"
                  placeholder="Email"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.username && errors.username ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="username"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <Field
                  data-testid="SignupPassword"
                  type="password"
                  name="password"
                  autoComplete="off"
                  placeholder="Password"
                  className={`mt-2 form-control
              ${touched.password && errors.password ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <Field
                  data-testid="confirmPassword"
                  type="password"
                  name="ConfirmPassword"
                  autoComplete="off"
                  placeholder="Confirm Password"
                  className={`mt-2 form-control
              ${touched.password && errors.password ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="ConfirmPassword"
                  className="invalid-feedback"
                />
              </div>
              <div className="response-error">
                <span className="login-error">{loginMessage}</span>
              </div>
              <div
                className=" pt-5 pb-1 d-flex justify-content-center conncetButton"
                data-testid="submit"
              >
                <ConnectButton
                  type={"submit"}
                  text={"Sign up"}
                  isActive={!Formik.isValid}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default SignUpForm;
