import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";

import ConnectButton from "../connect-button/connectButton";
import { dachboardSignIn } from "../../../services/index";
import logo from "../../../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Please enter the Username"),
  password: Yup.string().required("Please enter the Password"),
});

function SignInForm() {
  const [loginMessage, setLoginMessage] = useState("");

  let navigate = useNavigate();

  const installStatus = useSelector(
    (state) => state?.entities?.dashboard?.completedInstallation
  );

  let store = useStore();

  const updateLoginStatus = () => {
    store.dispatch({
      type: "dashboard/updateLoginStatus",
      payload: {
        isLoggedin: true,
      },
    });
  };

  const goMonitor = () => {
    navigate(installStatus ? "/monitor" : "/installer");
  };

  return (
    <div id="SignIn" className="SignIn" data-testid="SignIn">
      <div className="text-center py-5">
        <img className="img-fluid" src={logo} alt="logoImg" />
      </div>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, isSubmitting) => {
          dachboardSignIn(values.username, values.password)
            .then((response) => {
              if (response?.success) {
                updateLoginStatus();
                goMonitor();
              } else {
                setLoginMessage(
                  response?.message?.message ?? response?.message
                );
              }
            })
            .catch((error) => {
              setLoginMessage(error?.message);
            });
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <div className="form-body px-3">
            <Form>
              <div className="form-group">
                <Field
                  data-testid="username"
                  type="text"
                  name="username"
                  placeholder="Email"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.username && errors.username ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="username"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <Field
                  data-testid="password"
                  type="password"
                  name="password"
                  autoComplete="off"
                  placeholder="Password"
                  className={`mt-2 form-control
              ${touched.password && errors.password ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
              </div>
              <div className="response-error">
                <span className="login-error">{loginMessage}</span>
              </div>
              <div
                className="pt-5 pb-1 d-flex justify-content-center  conncetButton"
                data-testid="submit"
              >
                <ConnectButton
                  type={"submit"}
                  text={"Sign in"}
                  isActive={!Formik.isValid}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default SignInForm;
