import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/rubik";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Provider } from "react-redux";
import { defaultStore } from "./store";
import Home from "./routes";
import { ToastProvider } from "react-toast-notifications";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={defaultStore.store}>
    <PersistGate persistor={defaultStore.persistor}>
      <ToastProvider>
        <Home />
      </ToastProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
