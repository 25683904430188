import { services } from "../configVariables";
import { axiosProviderNode } from "../../../http/httpService";

async function checkSyncBackend(maxappsHost) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_CHECK_SYNC_BACKEND;

    const body = {
      maxappsHost,
    };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 500) {
      return {
        status: false,
        message: "Cannot connect to Maxapps sync Server",
      };
    }
    return error + 11111;
  }
}

export default checkSyncBackend;
