import "./SecureLogin.scss";

import React, { useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import BackButton from "../../global-components/back-button/BackButton";
import ForwardButton from "../../global-components/forward-button/ForwardButton";
import ToggleButton from "../../global-components/switch-toggle/ToggleButton";

import { FaEye, FaEyeSlash } from "react-icons/fa";

import { maximoLogin } from "../../../services/index";
import { useStore } from "react-redux";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Please enter maximo Username"),
  password: Yup.string().required("Please enter maximo Password"),
  url: Yup.string().required("Please enter maximo URL"),
  oslcUrl: Yup.string().required("Please enter Oslc URL"),
  maxRestUrl: Yup.string().required("Please enter MaxRest URL"),
});

const LoginSchemaWithApiKey = Yup.object().shape({
  username: Yup.string().required("Please enter maximo Username"),
  userApiKey: Yup.string().required("Please enter user api key"),
  url: Yup.string().required("Please enter maximo URL"),
  oslcUrl: Yup.string().required("Please enter Oslc URL"),
  maxRestUrl: Yup.string().required("Please enter MaxRest URL"),
});

function SecureLogin(props) {
  const [useApiKey, setUseApiKey] = useState(false);
  const { handleNext, handlePrevious } = props;
  let store = useStore();

  const [loginMessage, setLoginMessage] = useState("");

  const method = "ldap";

  const [visiblePassword, setVisiblePassword] = useState(false);

  const { hostname, port, protocol } = window.location;

  const maxappsHost = protocol.concat("//", hostname, ":", port);

  const handleTogglePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  const fineUrl = (url) => {
    let cleanUrl = url.trim();
    if (!(url.startsWith("http://") || url.startsWith("https://"))) {
      return `http://${cleanUrl}`;
    } else {
      return cleanUrl;
    }
  };

  const submitForm = (values, setSubmitting) => {
    setLoginMessage("");
    const maximoUrl = fineUrl(values.url);
    const oslcUrl = fineUrl(values.oslcUrl);
    const maxRestUrl = fineUrl(values.maxRestUrl);

    maximoLogin(
      values.username,
      values.password,
      maximoUrl,
      oslcUrl,
      maxRestUrl,
      maxappsHost,
      method,
      null,
      values.userApiKey
    )
      .then((response) => {
        if (response?.content?.status?.toUpperCase() === "SUCCESS") {
          store.dispatch({
            type: "dashboard/updateMaximoLoginStatus",
            payload: {
              status: true,
            },
          });
          handleNext();
        } else {
          const errorMessage =
            response?.content && response?.content?.message !== ""
              ? response?.content?.message
              : response.message;
          setLoginMessage(errorMessage);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setLoginMessage(error?.response?.data?.message);
      });
  };

  return (
    <div id="SecureLogin" className="SecureLogin" data-testid="SecureLogin">
      <Formik
        initialValues={{
          username: "",
          password: "",
          url: "",
          oslcUrl: "",
          maxRestUrl: "",
          userApiKey: "",
        }}
        validationSchema={useApiKey ? LoginSchemaWithApiKey : LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (useApiKey) {
            values.password = "";
          } else {
            values.userApiKey = "";
          }
          submitForm(values, setSubmitting);
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <div>
            <div className="col-lg-12">
              <p className="form-title">
                Connect MAXapps server to a MAXIMO Instance
              </p>
              <br />
            </div>

            <Form>
              <div className="form-group">
                <label
                  className={
                    values.url ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  Maximo url
                </label>

                <Field
                  onBlur={(e) => {
                    let trimmedUrl = fineUrl(e.currentTarget.value);
                    trimmedUrl = trimmedUrl.slice(
                      0,
                      e.currentTarget.value.lastIndexOf("/")
                    );
                    values.oslcUrl = `${e.currentTarget.value}/oslc`;
                    values.maxRestUrl = `${trimmedUrl}/maxrest`;
                  }}
                  data-testid="secure-url"
                  type="text"
                  name="url"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.url && errors.url ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="url"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label
                  className={
                    values.username
                      ? "filled  input-label"
                      : "not-filled input-label"
                  }
                  htmlFor="url"
                >
                  Username
                </label>
                <Field
                  data-testid="secure-username"
                  type="text"
                  name="username"
                  autoComplete="off"
                  className={`mt-2 form-control
            ${touched.username && errors.username ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="username"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="useApiKeyCheckbox"
                  checked={useApiKey}
                  onChange={(e) => setUseApiKey(e.target.checked)}
                />
                <label htmlFor="useApiKeyCheckbox">
                  Use API Key instead of username/password
                </label>
              </div>
              {useApiKey ? (
                <div className="form-group">
                  <label
                    className={
                      values.userApiKey ? "filled input-label" : " input-label"
                    }
                    htmlFor="userApiKey"
                  >
                    API Key
                  </label>
                  <Field
                    data-testid="standard-userApiKey"
                    type="text"
                    name="userApiKey"
                    autoComplete="off"
                    className={`mt-2 form-control ${
                      touched.userApiKey && errors.userApiKey
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="userApiKey"
                    className="invalid-feedback"
                  />
                </div>
              ) : (
                <>
                  <div className="form-group">
                    <label
                      className={
                        values.password ? "filled  input-label" : " input-label"
                      }
                      htmlFor="url"
                    >
                      Password
                    </label>
                    <div className="password-input">
                      <Field
                        style={{ paddingRight: "50px" }}
                        data-testid="secure-password"
                        show="true"
                        type={visiblePassword ? "text" : "password"}
                        name="password"
                        autoComplete="off"
                        className={`mt-2 form-control
                ${touched.password && errors.password ? "is-invalid" : ""}`}
                      />
                      <span
                        className="show-icon-container"
                        onClick={handleTogglePassword}
                      >
                        {visiblePassword ? (
                          <div className="show-icon">
                            <FaEyeSlash size={20} color="#4C36A5" />
                          </div>
                        ) : (
                          <div className="show-icon">
                            <FaEye size={20} color="#4C36A5" />
                          </div>
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>
                </>
              )}

              <div className="form-group">
                <label
                  className={
                    values.oslcUrl ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  Oslc url
                </label>
                <Field
                  data-testid="secure-oslcUrl"
                  type="text"
                  name="oslcUrl"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.oslcUrl && errors.oslcUrl ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="oslcUrl"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.maxRestUrl ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  MaxRest url
                </label>
                <Field
                  data-testid="secure-maxRestUrl"
                  type="text"
                  name="maxRestUrl"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.maxRestUrl && errors.maxRestUrl ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="maxRestUrl"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-options">
                <div className="toggle-label">
                  <span>Secure method</span>
                </div>
                <ToggleButton label="Secure method" isOn={method} />
              </div>
              <div className="response-error">
                <span className="login-error">{loginMessage}</span>
              </div>
              <div className="submit">
                <BackButton
                  type={"button"}
                  icon="chevron-left"
                  text="Cancel"
                  handleAction={() => handlePrevious()}
                  isActive={!isSubmitting}
                />
                <ForwardButton
                  type={"submit"}
                  text="Connect"
                  isActive={{ toLoad: true, disable: isSubmitting }}
                  icon="checked"
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default SecureLogin;
