import "./RetrySyncButton.scss";

import React from "react";

import Lottie from "react-lottie";
import Loading from "../../../../../assets/lottie-files/loader.json";

import Icon from "../../../../designer/components/icon";

function RetrySyncButton(props) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { icon = null, text, handleAction = () => {}, isActive } = props;

  return (
    <button
      id="RetrySyncButton"
      className={!isActive ? "RetrySyncButtonDisabled" : "RetrySyncButton"}
      onClick={() => handleAction()}
      disabled={!isActive}
      data-testid="RetrySyncButton"
    >
      <span
        className="retrysyncbutton-text"
        data-testid="retrysync-button-text"
      >
        {text}
      </span>
      {!isActive ? (
        <Lottie
          isClickToPauseDisabled={true}
          options={LoadingOptions}
          height={35}
          width={35}
          style={{
            cursor: "auto",
          }}
        />
      ) : (
        icon && <Icon icon={icon} size={25} color="#fff" />
      )}
    </button>
  );
}

export default RetrySyncButton;
