import "./ToRetryItem.scss";

import React, { useState } from "react";

import AlertDialog from "../../../global-components/alert-dialog/AlertDialog";

import InstallError from "../../../../../../assets/lottie-files/error.json";
import Warning from "../../../../../../assets/lottie-files/warning.json";
import Lottie from "react-lottie";
import installScript from "./services/installScript";

const ToRetryItem = (props) => {
  const WarningOptions = {
    loop: false,
    autoplay: true,
    animationData: Warning,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const ErrorOptions = {
    loop: false,
    autoplay: true,
    animationData: InstallError,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { script = null } = props;
  const [scriptItem, setScriptItem] = useState(script);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const [dialogType, setDialogType] = useState("error");

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = (type) => {
    setDialogType(type);
    setShowDialog(true);
  };
  const handleRetry = (scriptName) => {
    installScript(
      setScriptItem,
      setErrorMessage,
      setIsRetrying,
      openDialog,
      scriptName,
      script
    );
  };

  return (
    <div className="ToRetryItem" id="ToRetryItem" data-testid="ToRetryItem">
      <div className="retry-item">
        <p>{scriptItem?.name?.slice(0, -3)}</p>
        {scriptItem?.status && scriptItem?.loggerStatus ? (
          <p className="success-status">Installed</p>
        ) : (
          <div className="error-section">
            {scriptItem?.status && !scriptItem?.loggerStatus && (
              <button
                className="file-error"
                onClick={() => {
                  openDialog("warning");
                }}
              >
                <Lottie
                  isClickToPauseDisabled={true}
                  options={WarningOptions}
                  height={27}
                  width={27}
                  style={{
                    cursor: "auto",
                  }}
                />
              </button>
            )}
            {!scriptItem?.status && (
              <button
                className="file-error"
                onClick={() => {
                  openDialog("error");
                }}
              >
                <Lottie
                  isClickToPauseDisabled={true}
                  options={ErrorOptions}
                  height={27}
                  width={27}
                  style={{
                    cursor: "auto",
                  }}
                />
              </button>
            )}
          </div>
        )}
      </div>
      <AlertDialog
        dialogHeader={errorMessage ? errorMessage : scriptItem?.message}
        show={showDialog}
        CloseDialog={closeDialog}
        type="alert"
      >
        <div className="close-alert-error">
          <button
            className={`close-btn ${dialogType}`}
            onClick={(e) => {
              e.stopPropagation();
              closeDialog();
            }}
            data-testid="No"
          >
            <span className="dialog-btn-text">OK</span>
          </button>
        </div>
      </AlertDialog>
    </div>
  );
};

export default ToRetryItem;
