import "./index.scss";

import React from "react";

import ExplorerItems from "./explorer-items";

function Explorer({ data = {} }) {
  return (
    <div className="Explorer" id="Explorer" data-testid="Explorer">
      <div className="bar-title">
        <span className="compTitle">Components</span>
      </div>
      <div className="explorer-navigator">
        <ExplorerItems data={data} />
      </div>
    </div>
  );
}

export default Explorer;
