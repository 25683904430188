import { getMaximoConfig } from "../../../../services";

async function serverConfig({ setAddress, setProtocol }) {
  try {
    await getMaximoConfig().then((res) => {
      setAddress(res?.content?.server?.address);
      setProtocol(res?.content?.server?.protocol);
    });
  } catch (error) {
    alert("Error getMaximoConfig ", error);
    console.error("Error getMaximoConfig ", error);
  }
}

export default serverConfig;
