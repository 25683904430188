import React from "react";

import InstallScreen from "../global-components/install-screen/InstallScreen";

function AutoscriptsInstall({ handleNext, handlePrevious }) {
  return (
    <div
      id="AutoscriptsInstall"
      className="AutoscriptsInstall"
      data-testid="AutoscriptsInstall"
    >
      <InstallScreen handleNext={handleNext} handlePrevious={handlePrevious} />
    </div>
  );
}

export default AutoscriptsInstall;
