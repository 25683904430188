import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardApp from "../dashboard/Dashboard";
import Stepper from "./components/Stepper";
import "./Installer.scss";

function Installer() {
  const checkAvailableConfig = useSelector(
    (state) => state?.entities?.dashboard?.availableConfig
  );
  const isLoggedin = localStorage.getItem("authorization") ? true : false;
  let navigate = useNavigate();

  useEffect(() => {
    if (checkAvailableConfig && !isLoggedin) navigate("/");
  }, [checkAvailableConfig, isLoggedin, navigate]);
  return (
    <DashboardApp>
      <Stepper />
    </DashboardApp>
  );
}

export default Installer;
