import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../http/httpService";
async function getFileContent(store, filename, readOnly, addToast) {
  try {
    if (filename && filename !== "") {
      const transactionNodeService = services.NODE_ENDPOINT_GET_FILE_CONTENT;

      const res = await serviceRequest.get(transactionNodeService, {
        filename,
      });

      if (res?.data?.success) {
        try {
          store.dispatch({
            type: "explorer/dataAdded",
            paylaod: {
              skeleton: res?.data?.content?.skeleton,
              readOnly: readOnly,
            },
          });
        } catch (error) {
          addToast(error?.message || error, {
            appearance: "error",
            autoDismissTimeout: 3000,
            autoDismiss: true,
            transitionDuration: 600,
          });
        }
      } else {
        addToast(res.data.message, {
          appearance: "error",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      }

      return res;
    }

    return {
      data: { success: false },
    };
  } catch (error) {
    addToast("Server is unreachable!", {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });

    return {
      data: { success: false },
    };
  }
}

export default getFileContent;
