function stepper() {
  let currentIndex = 0;

  const setIndex = (val) => (currentIndex = val);
  const getIndex = () => currentIndex;

  return {
    setIndex,
    getIndex,
  };
}

export default stepper();
