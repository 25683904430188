import "./PostInstall.scss";

import React, { useState, useEffect, useCallback } from "react";

import ForwardButton from "../forward-button/ForwardButton";
import InstalledList from "./installed-list/InstalledList";
import AlertDialog from "../alert-dialog/AlertDialog";

import Loading from "../../../../../assets/lottie-files/loader.json";
import Lottie from "react-lottie";
import __getScripts from "./services/__getScripts";

function PostInstall({ handleNext, title }) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    openDialog();
  }, []);

  const prepareData = useCallback(() => {
    __getScripts(setData, handleError);
  }, [handleError]);

  useEffect(() => {
    if (!data) {
      prepareData();
    }
    return () => {};
  }, [data, prepareData]);

  return (
    <div id="PostInstall" className="PostInstall" data-testid="PostInstall">
      <div className="posinstall-header">
        <p className="posinstall-title">{title}</p>
      </div>
      <div className="scripts-list">
        {data ? (
          <InstalledList data={data} />
        ) : (
          <Lottie
            isClickToPauseDisabled={true}
            options={LoadingOptions}
            height={60}
            width={60}
            style={{
              cursor: "auto",
            }}
          />
        )}
      </div>
      <div className="control-btns">
        <ForwardButton
          icon="chevron-right"
          text="Next"
          handleAction={handleNext}
          isActive={{ toLoad: false, disable: !data }}
        />
      </div>
      <AlertDialog
        dialogHeader={errorMessage}
        show={showDialog}
        CloseDialog={closeDialog}
        type="alert"
      >
        <div className="close-alert-error">
          <button
            className="close-btn"
            onClick={(e) => {
              e.stopPropagation();
              prepareData();
              closeDialog();
            }}
            data-testid="No"
          >
            <span className="dialog-btn-text">Retry</span>
          </button>
        </div>
      </AlertDialog>
    </div>
  );
}

export default PostInstall;
