import "./ToRetryList.scss";

import React from "react";

import ToRetryItem from "./to-retry-item/ToRetryItem";

function ToRetryList(props) {
  const { scripts = null } = props;

  return (
    <div className="ToRetryList" id="ToRetryList" data-testid="ToRetryList">
      <ul className="scripts-box">
        {scripts?.map((item, index) => {
          return <ToRetryItem key={index} script={item} />;
        })}
      </ul>
    </div>
  );
}

export default ToRetryList;
