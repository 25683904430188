import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "../../configVariables";

async function getRamUsage(params = {}) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_RAM_USAGE;

    const res = await axiosProviderNode.get(transactionNodeService, params);
    return res?.data;
  } catch (error) {
    throw error;
  }
}

export default getRamUsage;
