import { axiosProviderNode } from "../../../../../../http/httpService";
import { services } from "../../../../../../installer/services/configVariables";

async function deleteIcons(iconName, addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DELETE_ICON;

    const body = {
      filename: iconName,
    };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    addToast(res?.data?.message?.toString(), {
      appearance: res?.data?.success ? "success" : "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });

    return res.data.success;
  } catch (error) {
    addToast(error?.message?.toString(), {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
  }
}

export default deleteIcons;
