import "./InstalledList.scss";

import React from "react";

import InstalledItem from "./installed-item/InstalledItem";

function InstalledList(props) {
  const { data = [] } = props;
  const uninstalledSriptsNumber = data?.filter((e) => !e.status)?.length || 0;

  const filesRenderer = data
    ?.sort()
    ?.reverse()
    ?.map((script, index) => {
      return <InstalledItem key={index} script={script} />;
    });

  return (
    <div
      id="InstalledList"
      className="InstalledList"
      data-testid="InstalledList"
    >
      {uninstalledSriptsNumber > 0 ? (
        <p className="failed-scripts">
          Total Failed Installed Scripts : {uninstalledSriptsNumber}
        </p>
      ) : null}
      <div className="scripts-box">{filesRenderer}</div>
    </div>
  );
}

export default InstalledList;
