import "./index.scss";

import React from "react";

import { Card } from "react-bootstrap";

import Icon from "../../../../designer/components/icon";

function IssueInfoCard(props) {
  const {
    data = [],
    icon = "monitoring",
    deleteAllIssues,
    refreshData,
  } = props;
  const warningNumber = data?.filter((item) => item.level === "warn").length;
  const errorNumber = data?.filter((item) => item.level === "error").length;

  return (
    <div className="IssueNavBar d-flex p-2">
      <div className=" d-flex p-2">
        <div className="p-2 IssueInfoCard" data-testid="IssueInfoCard">
          <Card>
            <Card.Body>
              <Icon color="#f3b14e" icon={icon} />
              <div className={`px-3 warnings`}>
                <h4 data-testid="data-number">{warningNumber ?? 0}</h4>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="p-2 IssueInfoCard" data-testid="IssueInfoCard">
          <Card>
            <Card.Body>
              <Icon color="#f34848" icon={icon} />
              <div className={`px-3 errors`}>
                <h4 data-testid="data-number">{errorNumber ?? 0}</h4>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="d-flex">
        <div className="p-3" data-testid="IssueInfoCard">
          <div>
            <button onClick={refreshData}>Refresh</button>
          </div>
        </div>
        <div className="p-3" data-testid="IssueInfoCard">
          <div>
            <button onClick={deleteAllIssues}>Delete All Issues</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IssueInfoCard;
