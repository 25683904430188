import "./index.scss";
import React from "react";
import { Accordion } from "react-bootstrap";

function IssueBody(props) {
  const { issue = {} } = props;

  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          {issue?.service} | {issue?.level}
        </Accordion.Header>

        <Accordion.Body>
          <p className="pt-3" style={{ overflowWrap: "break-word" }}>
            message : {issue?.message}
          </p>
          <p className="pt-3" style={{ overflowWrap: "break-word" }}>
            level : {issue?.level}
          </p>
          <p className="pt-3" style={{ overflowWrap: "break-word" }}>
            service : {issue?.service}
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default IssueBody;
