function transformForXml(obj) {
  if (!obj || typeof obj !== "object") {
    return {};
  }
  if (obj?.length === 0) {
    return {};
  }

  let newObj = {};

  for (var ogKey in obj) {
    if (Array.isArray(obj[ogKey])) {
      let test = [];
      for (var i in obj[ogKey]) {
        test.push(transformForXml(obj[ogKey][i]));
      }
      newObj[ogKey] = [...test];
    } else if (typeof obj[ogKey] === "object") {
      newObj[ogKey] = transformForXml(obj[ogKey]);
    } else {
      if (
        ogKey?.toLowerCase() !== "id" &&
        ogKey?.toLowerCase() !== "itemorder"
      ) {
        newObj["$"] = newObj["$"] || {};
        newObj["$"][ogKey] = obj[ogKey];
      }
    }
  }
  return newObj;
}

export default transformForXml;
