import "./index.scss";

import React, { useState } from "react";

import { useStore } from "react-redux";

import ObjectSubItem from "./object-sub-item";
import ConfirmDialog from "../../../../confirmation-dialog";

import { setIn } from "lodash-redux-immutability";

import removeNulls from "../../../../../../services/helpers/removeNulls";

function SubItem({
  setCurentParent,
  data = {},
  parent = [],
  setEdited,
  edited,
  displayAddNew = () => {},
  currentParent = [],
  readOnly = true,
  setFirstOpen,
  currentOpen,
  setCurrentOpen,
}) {
  const [open, setOpen] = useState({});

  const [showDialog, setShowDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [currentPath, setCurrentPath] = useState("");

  const CloseDialog = () => setShowDialog(false);

  const OpenDialog = () => setShowDialog(true);

  let store = useStore();

  const handleDelete = () => {
    try {
      const globalSkeleton = store.getState()?.entities?.explorer?.skeleton;
      let newData = setIn(globalSkeleton, currentPath, undefined);
      const plusData = removeNulls(newData);
      store.dispatch({
        type: "explorer/updateData",
        paylaod: {
          skeleton: { ...plusData },
          readOnly: false,
        },
      });
      setForEdited({}, [], "");
    } catch (error) {
      console.error("error-", error);
    }
  };

  const handleCollapse = (
    parent,
    indexElement,
    attrValue,
    objectAttr,
    openedFromParent
  ) => {
    setFirstOpen(parent);

    setCurentParent({
      parent: [...parent],
      item: "",
    });
    setEdited({
      [indexElement]: !edited[indexElement],
    });

    setOpen({
      [indexElement]: openedFromParent
        ? !openedFromParent
        : !open[indexElement],
    });
    setCurrentOpen({
      [indexElement]: openedFromParent
        ? !openedFromParent
        : !open[indexElement],
    });

    !open[indexElement]
      ? setForEdited(attrValue, indexElement, objectAttr)
      : setForEdited({}, []);
  };

  const handleAdd = (indexElement, objectAttr) => {
    displayAddNew(true, {
      parent: indexElement,
      item: objectAttr,
    });
  };

  const deleteItem = (indexElement, label) => {
    OpenDialog();
    setCurrentPath(indexElement);
    setCurrentItem(label);
  };

  const setForEdited = (val, parent, component) => {
    store.dispatch({
      type: "editor/dataAdded",
      paylaod: {
        data: val,
        parent,
        component,
      },
    });
  };

  return (
    <React.Fragment>
      <div id="SubItem" className="SubItem">
        {data && (
          <div>
            {Object.entries(data).map(([objectAttr, attrValue], attrIndex) => {
              let hasObject = false;
              if (attrValue instanceof Object) {
                Object.keys(attrValue).forEach((item) => {
                  if (attrValue[item] instanceof Object) {
                    hasObject = true;
                  }
                });
              }
              let indexElement = [...parent, objectAttr];
              let openedFromParent = false;

              if (
                !Array.isArray(attrValue) &&
                attrValue instanceof Object &&
                Array.isArray(currentParent) &&
                indexElement?.toString()?.length <=
                  currentParent?.toString()?.length &&
                currentParent?.toString()?.includes(indexElement?.toString())
              ) {
                openedFromParent = true;
              }

              return (
                <div key={attrIndex}>
                  {Array.isArray(attrValue) &&
                    attrValue.map((item, index) => {
                      hasObject = false;
                      if (item instanceof Object) {
                        Object.keys(item).forEach((elem) => {
                          if (attrValue?.[index]?.[elem] instanceof Object) {
                            hasObject = true;
                          }
                        });
                      }

                      let indexElementArray = [
                        ...parent,
                        objectAttr,
                        index.toString(),
                      ];

                      if (
                        Array.isArray(currentParent) &&
                        indexElementArray?.toString()?.length <=
                          currentParent?.toString()?.length &&
                        currentParent
                          ?.toString()
                          ?.includes(indexElementArray?.toString())
                      ) {
                        openedFromParent = true;
                      }

                      return item instanceof Object ? (
                        <div key={index} className={`object-item `}>
                          <ObjectSubItem
                            objectAttr={objectAttr}
                            indexElement={indexElementArray}
                            openedFromParent={openedFromParent}
                            readOnly={readOnly}
                            attrValue={data[objectAttr]?.[index]}
                            open={open}
                            hasObject={hasObject}
                            parent={parent}
                            label={
                              data[objectAttr]?.[index]?.label
                                ? data[objectAttr]?.[index]?.label
                                : `${objectAttr}  ${index + 1}`
                            }
                            handleCollapse={handleCollapse}
                            deleteItem={deleteItem}
                            handleAdd={handleAdd}
                            currentOpen={currentOpen}
                          />
                          {hasObject &&
                            (openedFromParent || open[indexElementArray]) && (
                              <SubItem
                                data={data[objectAttr]?.[index]}
                                parent={indexElementArray}
                                edited={edited}
                                setEdited={setEdited}
                                displayAddNew={displayAddNew}
                                currentParent={currentParent}
                                setCurentParent={setCurentParent}
                                readOnly={readOnly}
                                setFirstOpen={setFirstOpen}
                                currentOpen={currentOpen}
                                setCurrentOpen={setCurrentOpen}
                              />
                            )}
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  <div
                    className={`object-item ${
                      openedFromParent || open[indexElement]
                        ? "level-selected"
                        : ""
                    }`}
                  >
                    {!Array.isArray(attrValue) &&
                    attrValue instanceof Object ? (
                      <React.Fragment>
                        <ObjectSubItem
                          objectAttr={objectAttr}
                          indexElement={indexElement}
                          openedFromParent={openedFromParent}
                          readOnly={readOnly}
                          attrValue={attrValue}
                          open={open}
                          hasObject={hasObject}
                          parent={parent}
                          label={
                            data[objectAttr]?.label
                              ? data[objectAttr]?.label
                              : objectAttr
                          }
                          handleCollapse={handleCollapse}
                          deleteItem={deleteItem}
                          handleAdd={handleAdd}
                          currentOpen={currentOpen}
                        />
                        {(openedFromParent || open[indexElement]) && (
                          <SubItem
                            data={attrValue}
                            parent={indexElement}
                            edited={edited}
                            setEdited={setEdited}
                            displayAddNew={displayAddNew}
                            currentParent={currentParent}
                            setCurentParent={setCurentParent}
                            readOnly={readOnly}
                            setFirstOpen={setFirstOpen}
                            currentOpen={currentOpen}
                            setCurrentOpen={setCurrentOpen}
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ConfirmDialog
        confirmQuestion="Are you sure to delete this item?"
        itemName={currentItem}
        show={showDialog}
        CloseDialog={CloseDialog}
        ConfirmAction={handleDelete}
      />
    </React.Fragment>
  );
}
export default SubItem;
