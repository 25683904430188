import { axiosProviderNode } from "../../../../../../http/httpService";
import { services } from "../../../../../../installer/services/configVariables";

async function getAllIcons(addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_ALL_ICONS;

    const res = await axiosProviderNode.get(transactionNodeService);

    return res?.data?.data;
  } catch (error) {
    addToast(error?.message?.toString(), {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
    return error;
  }
}

export default getAllIcons;
