import "../signIn.scss";

import React from "react";

import Icon from "../../../../designer/components/icon";

function ConnectButton(props) {
  const { icon = null, text, isActive, type = "" } = props;
  return (
    <button
      type={type}
      id="ConnectButton"
      className={!isActive ? "connectiondisabled" : "activeButton"}
      disabled={!isActive}
      data-testid="ConnectButton"
    >
      <span className="ConnectButton-text">{text}</span>
      {icon && <Icon icon={icon} size={20} color="#fff" />}
    </button>
  );
}

export default ConnectButton;
