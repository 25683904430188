import "./index.scss";

import React, { useEffect, useCallback } from "react";

import { useStore, useSelector } from "react-redux";

import { useToasts } from "react-toast-notifications";

import Draft from "./draftComp";
import Version from "./version";

import getAllFiles from "../../../services/getAllFiles";
import fetchListVersion from "../../../services/revision";

function Preview() {
  let store = useStore();

  let draftDisplay = useSelector(
    (state) => state?.entities?.explorer?.draftDisplay
  );
  let versionsList = useSelector((state) =>
    state?.entities?.revision?.list ? state?.entities?.revision.list : []
  );

  const { addToast } = useToasts();

  const { data } = fetchListVersion(versionsList);

  const lastVersion = data?.[0];

  const retrieveAllFiles = useCallback(
    async (store) => await getAllFiles(store, addToast),
    [addToast]
  );

  useEffect(() => {
    store.dispatch({
      type: "reset",
    });
    retrieveAllFiles(store);
  }, [retrieveAllFiles, store]);

  useEffect(() => {
    if (!lastVersion) {
      store.dispatch({
        type: "reset",
      });
    }
  }, [lastVersion, store]);

  useEffect(() => {
    const draftDisplay = store?.getState()?.entities?.explorer?.draftDisplay;
    if (lastVersion?.filename && !draftDisplay) {
      const existSelectedVersion =
        store?.getState()?.entities?.revision?.itemSelected ?? {};

      if (Object.keys(existSelectedVersion)?.length === 0) {
        store.dispatch({
          type: "revision/setSelectedItem",
          paylaod: {
            obj: { [lastVersion?.filename]: true },
          },
        });
      }
    }
  }, [store, lastVersion]);

  return (
    <div id="Preview" className="Preview" data-testid="">
      <div className="bar-title">
        <span className="revisionTitle">Revision</span>
      </div>
      <div className="revision-content">
        {draftDisplay && <Draft lastVersion={lastVersion} />}
        {data?.map((vers, index) => {
          return (
            <React.Fragment key={index}>
              <Version
                data={vers}
                versionsData={versionsList}
                disable={draftDisplay}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Preview;
