export const obj = {
  maxapps: {
    page: { title: "page", icon: "add" },
    gis: { title: "gis", icon: "add" },
    conditions: { title: "conditions", icon: "add" },
    lookups: { title: "lookups", icon: "add" },
    script: { title: "script", icon: "add" },
    notifications: { title: "notifications", icon: "add" },
  },

  //notifications
  notifications: {
    notification: { title: "notification", icon: "add" },
  },
  notification: {
    message: { title: "message", icon: "add" },
  },
  //script function

  script: {
    function: { title: "function", icon: "add" },
  },
  //conditions

  conditions: {
    condition: { title: "condition", icon: "add" },
  },
  condition: {
    property: { title: "property", icon: "add" },
  },

  //lookups
  lookups: {
    lookup: { title: "lookup", icon: "add" },
  },
  lookup: {
    whereclause: { title: "whereclause", icon: "add" },
    body: { title: "body", icon: "add" },
  },
  //common
  body: {
    attribute: { title: "attribute", icon: "add" },
  },
  // page
  page: {
    app: { title: "application", icon: "add" },
    module: { title: "module", icon: "add" },
  },

  app: {
    list: { title: "list", icon: "add" },
    //for simple sr
    event: { title: "event", icon: "add" },
    form: { title: "form", icon: "add" },
  },
  module: {
    app: { title: "app", icon: "add" },
  },
  list: {
    whereclause: { title: "whereclause", icon: "add" },
    calendar: { title: "calendar", icon: "add" },
    map: { title: "map", icon: "add" },
    toolbar: { title: "toolbar", icon: "add" },
    fab: { title: "fab", icon: "add" },
    swipe: { title: "swipe", icon: "add" },
    hform: { title: "hform", icon: "add" },
    form: { title: "form", icon: "add" },
    body: { title: "body", icon: "add" },
    detail: { title: "detail", icon: "add" },
    marker: { title: "marker", icon: "add" },
    pending: { title: "pending", icon: "add" },
    resolved: { title: "resolved", icon: "add" },
  },
  calendar: {
    attribute: { title: "attribute", icon: "add" },
  },
  map: {
    pullup: { title: "pullup", icon: "add" },
    marker: { title: "marker", icon: "add" },
  },
  marker: {
    attribute: { title: "attribute", icon: "add" },
    //for gis
    body: { title: "body", icon: "add" },
    pin: { title: "pin", icon: "add" },
    detail: { title: "detail", icon: "add" },
  },
  pullup: {
    attribute: { title: "attribute", icon: "add" },
  },

  toolbar: {
    event: { title: "event", icon: "add" },
  },
  fab: {
    event: { title: "event", icon: "add" },
  },
  swipe: {
    event: { title: "event", icon: "add" },
  },
  form: {
    field: { title: "field", icon: "add" },
  },
  hform: {
    field: { title: "field", icon: "add" },
  },
  detail: {
    tab: { title: "tab", icon: "add" },
    section: { title: "section", icon: "add" },
  },
  tab: {
    list: { title: "list", icon: "add" },
    toolbar: { title: "toolbar", icon: "add" },
    section: { title: "section", icon: "add" },
    carroussel: { title: "carroussel", icon: "add" },
    timer: { title: "timer", icon: "add" },
    geopositioning: { title: "geopositioning", icon: "add" },
    hform: { title: "hform", icon: "add" },
    form: { title: "form", icon: "add" },
  },
  timer: {
    attribute: { title: "attribute", icon: "add" },
  },
  section: {
    attribute: { title: "attribute", icon: "add" },
  },

  //gis

  gis: {
    labortracking: { title: "labortracking", icon: "add" },
    list: { title: "list", icon: "add" },
  },
  labortracking: {
    marker: { title: "marker", icon: "add" },
  },
  pin: {
    attribute: { title: "attribute", icon: "add" },
  },
  //simple sr
  simple: {
    app: { title: "app", icon: "add" },
    lookups: { title: "lookups", icon: "add" },
  },
  pending: {
    status: { title: "status", icon: "add" },
  },
  resolved: {
    status: { title: "status", icon: "add" },
  },
};
export const colors = {
  advanced: "#dcfcf1",
  simple: "#ececec",
  lookups: "#f0ffd5",
  app: "#f6f4dc",
  module: "#ffefef",
  gis: "#fcdcfa",
  list: "#feeaf3",
  map: "#fcdcfa",
  swipe: "#f0ffd5",
  whereclause: "#d5effe",
  body: "#ececec",
  calendar: "#d5effe",
  form: "#feeaf3",
  toolbar: "#dcfcf1",
  fab: "#f0ffd5",
  event: "#f6f4dc",
  attribute: "#dcfcf1",
  pullup: "#ececec",
  marker: "#dcfcf1",
  pin: "#feeaf3",
  field: "#f0ffd5",
  hform: "#f0ffd5",
  detail: "#ececec",
  tab: "#dcfcf1",
  section: "#dcfcf1",
  carroussel: "#f0ffd5",
  function: "#fcdcfa",
  geopositioning: "#f0ffd5",
  labortracking: "#dcfcf1",
  property: "#dcdcf1",
  timer: "#f6f4dc",
  lookup: "#fcdcfa",
  condition: "#f0ffd5",
  pending: "#fcdcfa",
  resolved: "#dcfcf1",
  status: "#ececec",
};
