export const services = Object.freeze({
  NODE_ENDPOINT_MAXIMO_VERIFY: "autoscripts/verify",
  NODE_ENDPOINT_MAXIMO_MAXIMO_AUTH: "autoscripts/maximoAuth",
  NODE_ENDPOINT_SCRIPT_INSTALL: "autoscripts/install",
  NODE_ENDPOINT_ALL_SCRIPTS_INSTALL: "autoscripts/install/all",
  NODE_ENDPOINT_ONE_SCRIPT_INSTALL: "autoscripts/install",
  NODE_ENDPOINT_UNINSTALL_SCRIPT: "autoscripts/",
  NODE_ENDPOINT_DISCONNECT_MAXIMO: "autoscripts/disconnect",
  NODE_ENDPOINT_UNINSTALL_ALL: "autoscripts/uninstall/all",
  NODE_ENDPOINT_CLEAR: "autoscripts/clear",
  NODE_ENDPOINT_CHECK_AVAILABLE_CONFIG: "sys/info",
  NODE_ENDPOINT_CHECK_SYNC_BACKEND: "autoscripts/config/syncbackend",
  NODE_ENDPOINT_ADD_DOCLINK_OS: "autoscripts/create/doclink",
  NODE_ENDPOINT_ADD_IMGLIB_OS: "autoscripts/create/imglib",
  NODE_ENDPOINT_CONFIG_APPLICATION: "autoscripts/config/application",
  NODE_ENDPOINT_CONFIG_MAXMESSAGES: "autoscripts/config/maxmessages",
  NODE_ENDPOINT_VERIFY_OSLC_URL: "autoscripts/verifyUrl",
  NODE_ENDPOINT_UPLOAD_ICON: "assets/uploadIcon",
  NODE_ENDPOINT_GET_ALL_ICONS: "assets/get-all-icons",
  NODE_ENDPOINT_DELETE_ICON: "assets/deleteIcon",
  NODE_ENDPOINT_AUTOSCRIPTS_SCRIPTS: "autoscripts/scripts",
  NODE_ENDPOINT_RAM_USAGE: "stats/ramUsage",
  NODE_ENDPOINT_TRANSACTIONS_DATA: "stats/transactions",
  NODE_ENDPOINT_SERVER_CONFIG: "autoscripts/getServerConfig",
});
