import "./index.scss";

import React from "react";

import Icon from "../../../../../designer/components/icon";

function Accordioncontent(props) {
  const { data = {}, setDescriptioncontent, Level = 1 } = props;

  return (
    <>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2
            className="accordion-header"
            id={`panelsStayOpen-heading-${data.name}`}
            onClick={() =>
              setDescriptioncontent({
                description: data.description,
                name: data.name,
              })
            }
          >
            {data.subapi?.length > 0 ? (
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#panelsStayOpen-collapse-${data.name}`}
                aria-expanded="false"
                aria-controls={`panelsStayOpen-collapse-${data.name}`}
              >
                <Icon
                  icon="chevron-right"
                  className={`icon-chevron icon-chevron-level${Level}`}
                  size={15}
                  color="#6f53a3"
                />
                {data.name}
              </button>
            ) : (
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#panelsStayOpen-collapse-${data.name}`}
                aria-expanded="false"
                aria-controls={`panelsStayOpen-collapse-${data.name}`}
              >
                {data.name}
              </button>
            )}
          </h2>
          <div
            id={`panelsStayOpen-collapse-${data.name}`}
            className="accordion-collapse collapse"
            aria-labelledby={`panelsStayOpen-collapse-${data.name}`}
          >
            <div className="accordion-body">
              {data.subapi?.length > 0 && (
                <div className="accordion-body">
                  <ul className="timeline">
                    {data.subapi.map((sub, index) => (
                      <li key={index}>
                        <Accordioncontent
                          data={sub}
                          setDescriptioncontent={setDescriptioncontent}
                          Level={Level + 1}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Accordioncontent;
