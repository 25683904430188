import React, { useState } from "react";

import Icon from "../../icon";

function HoveredIcon({
  indexElement,
  iconName,
  defaultColor,
  hoverColor,
  size,
}) {
  const [iconHover, setIconHover] = useState({});

  return (
    <div
      id="HoveredIcon"
      className="HoveredIcon"
      onMouseEnter={() => {
        setIconHover({
          [indexElement]: true,
        });
      }}
      onMouseLeave={() => {
        setIconHover({
          [indexElement]: false,
        });
      }}
    >
      <Icon
        icon={iconName}
        color={iconHover[indexElement] ? hoverColor : defaultColor}
        size={size}
      />
    </div>
  );
}

export default HoveredIcon;
