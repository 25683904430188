import { services } from "../configVariables";
import { axiosProviderNode } from "../../../http/httpService";

async function clearMaximo(clearDb, toClearMaximo, checkVersions = true) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_CLEAR;

    const body = { checkVersions };

    const query_params = `?clearDb=${clearDb}&clearMaximo=${toClearMaximo}`;

    const res = await axiosProviderNode.post(
      transactionNodeService + query_params,
      body
    );

    return res?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export default clearMaximo;
