import { axiosProviderNode as serviceRequest } from "../../http/httpService";

import { services } from "./configVariable";

import getFileContent from "./getFileContent";

async function getAllFiles(store, addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_ALL_FILES;

    const res = await serviceRequest.get(transactionNodeService, {});

    if (res?.data?.success) {
      if (res?.data?.content?.length > 0) {
        await getFileContent(
          store,
          res?.data?.content[0]?.filename,
          true,
          addToast
        );
        store.dispatch({
          type: "revision/dataAdded",
          paylaod: {
            list: res?.data?.content,
          },
        });
      }
    } else {
      addToast(res?.data?.message, {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  } catch (error) {
    addToast("Server is unreachable!", {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
  }
}

export default getAllFiles;
