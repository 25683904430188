import "./SamlLogin.scss";

import React, { useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import BackButton from "../../global-components/back-button/BackButton";
import ForwardButton from "../../global-components/forward-button/ForwardButton";

import { maximoLogin } from "../../../services/index";
import { useStore } from "react-redux";

const LoginSchema = Yup.object().shape({
  url: Yup.string().required("Please enter URL"),
  apiKey: Yup.string().required("Please enter api key"),
  oslcUrl: Yup.string().required("Please enter Oslc URL"),
  maxRestUrl: Yup.string().required("Please enter MaxRest URL"),
});

function SamlLogin(props) {
  const { handleNext, handlePrevious } = props;

  const [loginMessage, setLoginMessage] = useState("");

  const { hostname, port, protocol } = window.location;

  const maxappsHost = protocol.concat("//", hostname, ":", port);

  const method = "saml";
  let store = useStore();

  const fineUrl = (url) => {
    let cleanUrl = url.trim();
    if (!(url.startsWith("http://") || url.startsWith("https://"))) {
      return `http://${cleanUrl}`;
    } else {
      return cleanUrl;
    }
  };

  const submitForm = (values, setSubmitting) => {
    setLoginMessage("");
    const samlUrl = fineUrl(values.url);
    const oslcUrl = fineUrl(values.oslcUrl);
    const maxRestUrl = fineUrl(values.maxRestUrl);
    maximoLogin(
      null,
      null,
      samlUrl,
      maxappsHost,
      oslcUrl,
      maxRestUrl,
      method,
      values.apiKey
    )
      .then((response) => {
        if (response?.content?.status === "success") {
          store.dispatch({
            type: "dashboard/updateMaximoLoginStatus",
            payload: {
              status: true,
            },
          });
          handleNext();
        } else {
          const errorMessage =
            response?.content && response?.content?.message !== ""
              ? response?.content?.message
              : response.message;
          setLoginMessage(errorMessage);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setLoginMessage(error?.response?.data?.message);
      });
  };

  return (
    <div id="SamlLogin" className="SamlLogin" data-testid="SamlLogin">
      <Formik
        initialValues={{
          url: "",
          apiKey: "",
          oslcUrl: "",
          maxRestUrl: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values, setSubmitting);
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <div>
            <div className="col-lg-12">
              <p className="form-title">
                Connect MAXapps server to a MAXIMO Instance
              </p>
              <br />
            </div>

            <Form>
              <div className="form-group">
                <label
                  className={
                    values.url ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  Saml url
                </label>
                <Field
                  onBlur={(e) => {
                    let trimmedUrl = fineUrl(e.currentTarget.value);
                    trimmedUrl = trimmedUrl.slice(
                      0,
                      e.currentTarget.value.lastIndexOf("/")
                    );
                    values.oslcUrl = `${e.currentTarget.value}/saml`;
                    values.maxRestUrl = `${trimmedUrl}/maxrest`;
                  }}
                  data-testid="saml-url"
                  type="text"
                  name="url"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.url && errors.url ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="url"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.apiKey ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  ApiKey
                </label>
                <Field
                  data-testid="saml-apiKey"
                  type="text"
                  name="apiKey"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.apiKey && errors.apiKey ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="apiKey"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.oslcUrl ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  Oslc url
                </label>
                <Field
                  data-testid="saml-oslcUrl"
                  type="text"
                  name="oslcUrl"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.oslcUrl && errors.oslcUrl ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="oslcUrl"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.maxRestUrl ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  MaxRest url
                </label>
                <Field
                  data-testid="saml-maxRestUrl"
                  type="text"
                  name="maxRestUrl"
                  autoComplete="off"
                  className={`mt-2 form-control
              ${touched.maxRestUrl && errors.maxRestUrl ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="maxRestUrl"
                  className="invalid-feedback"
                />
              </div>
              <div className="response-error">
                <span className="login-error">{loginMessage}</span>
              </div>
              <div className="submit">
                <BackButton
                  type={"button"}
                  icon="chevron-left"
                  text="Cancel"
                  handleAction={() => handlePrevious()}
                  isActive={!isSubmitting}
                />
                <ForwardButton
                  type={"submit"}
                  text="Connect"
                  isActive={{ toLoad: true, disable: isSubmitting }}
                  icon="checked"
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default SamlLogin;
