import getFailedListScripts from "./getFailedListScripts";
import installScriptPart from "./installScriptPart";

async function retryInstallOne(
  script = {},
  failedSciptsList = [],
  setIsVerifying = () => {},
  setEnableRetryButton = () => {},
  setFailedSciptsList = () => {}
) {
  setIsVerifying(true);

  const filtredList = getFailedListScripts(failedSciptsList);

  const findedScript = filtredList?.find(
    (e) => e?.scriptName === script?.scriptName
  );

  let responses = [];
  const result = await installScriptPart(findedScript);
  responses?.push(result);

  const filtredListResponses = getFailedListScripts(responses);
  if (filtredListResponses.length === 0) {
    setEnableRetryButton(false);
  }
  setIsVerifying(false);
  let newList = [];
  if (failedSciptsList?.length !== 0) {
    newList = failedSciptsList?.map((elem) => {
      const filter = responses?.filter((el) => {
        return el?.scriptName === elem?.scriptName;
      });
      if (filter.length) {
        return filter[0];
      }
      return elem;
    });
  }
  setFailedSciptsList(newList);
}

export default retryInstallOne;
