import "./MaximoSetup.scss";

import React, { useState, useEffect } from "react";

import ForwardButton from "../global-components/forward-button/ForwardButton";

import Lottie from "react-lottie";
import Loader from "../../../../assets/lottie-files/loader.json";

import { FaCheck, FaUndo } from "react-icons/fa";

import getFailedListScripts from "./services/getFailedListScripts";
import installScriptPart from "./services/installScriptPart";
import retryInstallOne from "./services/retryInstallOne";
import retryInstallAll from "./services/retryInstallAll";

import { installDoclinkOS } from "../../services/installDoclinkOS/installDoclinkOS";
import { installMaxappsApplication } from "../../services/installMaxappsApplication/installMaxappsApplication";
import { installMaxMessages } from "../../services/installMaxMessages/installMaxMessages";
import { installImglibOS } from "../../services/installImgLib/installImgLib";

function MaximoSetup({ handleNext }) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isVerifying, setIsVerifying] = useState(true);
  const [enableRetryButton, setEnableRetryButton] = useState(false);
  const [failedSciptsList, setFailedSciptsList] = useState([]);

  useEffect(() => {
    (async () => {
      setIsVerifying(true);
      const promises = [
        { scriptName: "Docklink OS", scriptInstall: installDoclinkOS },
        { scriptName: "Imglib OS", scriptInstall: installImglibOS },
        { scriptName: "Maxmessages", scriptInstall: installMaxMessages },
        {
          scriptName: "MAXAPPSMOBILE Application",
          scriptInstall: installMaxappsApplication,
        },
      ];

      let responses = [];
      for (let promise of promises) {
        const result = await installScriptPart(promise);
        responses.push(result);
      }

      const failedList = getFailedListScripts(responses);
      if (failedList.length > 0) {
        setEnableRetryButton(true);
      }

      setIsVerifying(false);
      setFailedSciptsList(responses);
    })();
  }, []);

  return (
    <div id="MaximoSetup" className="MaximoSetup" data-testid="MaximoSetup">
      <div className="setup-header">
        <div className="setup-title">
          <p className="title">
            Object structures, Maxmessages and Maxapps Application Installation
          </p>
        </div>
      </div>
      <div className="setup-footer">
        {isVerifying ? (
          <div className="setup-loader">
            <Lottie
              isClickToPauseDisabled={true}
              options={LoadingOptions}
              height={68}
              width={68}
              style={{
                cursor: "auto",
              }}
            />
          </div>
        ) : (
          <>
            <ForwardButton
              type="submit"
              text="Next"
              isActive={{ toLoad: false, disable: false }}
              icon="chevron-right"
              handleAction={handleNext}
            />
            <div className="setup-errors">
              {failedSciptsList?.map((script, index) => {
                const scriptName = script?.scriptName;
                let errorMessage = "";
                if (script?.success === false) {
                  errorMessage = script?.message
                    ? script?.message
                    : script?.content?.message;
                } else if (
                  script?.success === true &&
                  script?.content?.status.toUpperCase() === "FAILURE"
                ) {
                  errorMessage = script?.message
                    ? script?.message
                    : script?.content?.message;
                }

                const succesMessage = "is installed successfully";
                return (
                  <div key={index}>
                    <ul className="message-errors-container">
                      <>
                        <li className="label-error">{scriptName}:</li>
                        {errorMessage.length > 0 ? (
                          <>
                            <li className="message-error">{errorMessage}</li>
                            <button
                              id="ForwardButton"
                              className="RetryOneButton"
                              onClick={() => {
                                retryInstallOne(
                                  script,
                                  failedSciptsList,
                                  setIsVerifying,
                                  setEnableRetryButton,
                                  setFailedSciptsList
                                );
                              }}
                              data-testid="retry-one-script"
                            >
                              <FaUndo size={14} color="black" />
                            </button>
                          </>
                        ) : (
                          <li className="message-success">
                            {succesMessage}
                            <FaCheck size={20} color="green " />
                          </li>
                        )}
                      </>
                    </ul>
                  </div>
                );
              })}
            </div>
            {enableRetryButton ? (
              <button
                type="submit"
                id="Button"
                className="RetryButton"
                onClick={() => {
                  retryInstallAll(
                    failedSciptsList,
                    setIsVerifying,
                    setEnableRetryButton,
                    setFailedSciptsList
                  );
                }}
                data-testid="Button"
              >
                <span className="Retrybutton-text" data-testid="button-text">
                  Retry All
                </span>
              </button>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default MaximoSetup;
