import { checkSyncBackend } from "../../../../services";

function checkVerif(
  setSyncBackendStatus = () => {},
  sertSyncEror = () => {},
  setIsActive = () => {},
  maxappsHost
) {
  setIsActive(false);
  checkSyncBackend(maxappsHost)
    .then((response) => {
      if (response?.content?.status === "failure" || !response?.success) {
        setSyncBackendStatus(false);
        sertSyncEror(response?.content?.message ?? response?.message);
        setIsActive(true);
      } else {
        setSyncBackendStatus(true);
        setIsActive(true);
      }
    })
    .catch((error) => {
      setSyncBackendStatus(false);
      sertSyncEror(error.message);
      setIsActive(true);
    });
}

export default checkVerif;
