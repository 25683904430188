import "./index.scss";

import React from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";

import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

import SideNavItem from "./sidenav-item";

function DahsboardSideBar(props) {
  const { collapsed, toggled, handleToggleSidebar, handleCollapsedChange } =
    props;

  const navItems = [
    { name: "Monitor", icon: "monitoring" },
    { name: "Installer", icon: "descending" },
    { name: "Designer", icon: "edit" },
  ];

  return (
    <div className="DahsboardSideBar" data-testid="DahsboardSideBar">
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        <SidebarContent>
          <Menu iconShape="circle">
            <SideNavItem navItemsList={navItems} />
          </Menu>
        </SidebarContent>
        <SidebarHeader>
          <Menu iconShape="circle">
            {collapsed ? (
              <MenuItem
                icon={<FaAngleDoubleRight />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                prefix={<FaAngleDoubleLeft color="rgba(255, 255, 255, 0.5)" />}
                onClick={handleCollapsedChange}
              >
                <p className="collapse-menu">Collapse menu</p>
              </MenuItem>
            )}
          </Menu>
        </SidebarHeader>
      </ProSidebar>
    </div>
  );
}

export default DahsboardSideBar;
