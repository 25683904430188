function fetchListVersion(list) {
  if (!Array.isArray(list)) {
    return { data: [] };
  }
  if (list?.length === 0) {
    return { data: [] };
  }

  const data = list?.map((element) => {
    return {
      version: `V${element?.version ?? ""}`,
      release:
        new Date(element?.uploadDate) !== "Invalid Date" &&
        !isNaN(new Date(element?.uploadDate))
          ? new Date(element?.uploadDate).toLocaleString()
          : undefined,
      filename: element?.filename,
      maxOrder: element?.maxOrder,
      xmlAlias: element?.xmlAlias,
    };
  });

  return { data };
}

export default fetchListVersion;
