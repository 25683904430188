import React from "react";

import PreRequest from "../global-components/prerequest/PreRequest";

function AutoscriptsPrequest({ handleNext, handlePrevious }) {
  return (
    <div
      id="AutoscriptsPrequest"
      className="AutoscriptsPrequest"
      data-testid="AutoscriptsPrequest"
    >
      <PreRequest
        title="Installation of MAXapps components on Maximo"
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        actionName="Install"
      />
    </div>
  );
}

export default AutoscriptsPrequest;
