import "./MaximoLogin.scss";

import React, { useState } from "react";

import StandardLogin from "./standard-login/StandardLogin";
import SecureLogin from "./secure-login/SecureLogin";
import SamlLogin from "./saml-login/SamlLogin";

//   const URL_REGEX =
//     /^http([s])?:\/\/((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))(:(\d{1,5}))?\/([^\s]+)?$/;

//   const DNS_REGEX =
//     /^http([s])?:\/\/(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])?\/([^\s]+)?$/;

function MaximoLogin({ handleNext, handlePrevious }) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div id="MaximoLogin" className="MaximoLogin">
      <div className="form-body">
        <div className="bloc-tabs">
          <button
            data-testid="basic-login-btn"
            className={
              toggleState === 1
                ? "tabs active-tabs basic-login-btn"
                : "tabs basic-login-btn"
            }
            onClick={() => toggleTab(1)}
          >
            Standard Login
          </button>
          <button
            data-testid="secure-login-btn"
            className={
              toggleState === 2
                ? "tabs active-tabs secure-login-btn"
                : "tabs secure-login-btn"
            }
            onClick={() => toggleTab(2)}
          >
            Secure Login
          </button>
          <button
            data-testid="saml-login-btn"
            className={
              toggleState === 3
                ? "tabs active-tabs saml-login-btn"
                : "tabs saml-login-btn"
            }
            onClick={() => toggleTab(3)}
          >
            SAML Login
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <StandardLogin
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <SecureLogin
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <SamlLogin
              handleNext={handleNext}
              handlePrevious={handlePrevious}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaximoLogin;
