import "./index.scss";

import React, { useState } from "react";

import { useStore, useSelector } from "react-redux";

import { useToasts } from "react-toast-notifications";

import fileDownload from "js-file-download";

import xml2js from "minexml2js";

import FileUploader from "./file-uploader/FileUploader";
import ConfirmDialog from "../designer-body/confirmation-dialog";

import Icon from "../icon";

import transformForXml from "../../services/helpers/transformForXml";
import transformForStore from "../../services/helpers/transformForStore";
import creactDraft from "../../services/helpers/creactDraft";
import handleClickedItem from "../../services/helpers/handleClickedItem";

function Header() {
  let data = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  const { addToast } = useToasts();
  let store = useStore();

  const [showDialog, setShowDialog] = useState(false);
  const [confirmationHandler, setConfirmation] = useState("");
  const lastVersion = store.getState()?.entities?.revision?.lastVersion;
  const draftPresence = store.getState()?.entities?.explorer?.draftDisplay;

  const CloseDialog = () => setShowDialog(false);
  const OpenDialog = (comp) => {
    setShowDialog(true);
    setConfirmation(comp);
  };

  const onSave = async () => {
    const builder = new xml2js.Builder();
    const xml = builder.buildObject(transformForXml(data));
    const searchRegExp = new RegExp("&amp;", "g");
    const finalXML = xml.replace(searchRegExp, "&");

    fileDownload(finalXML, `file ${new Date().toLocaleString()}.xml`);
  };

  const handleSelectFile = (selectedFile) => {
    if (draftPresence) {
      OpenDialog(selectedFile);
    } else {
      changeHandler(selectedFile);
    }
  };

  const changeHandler = async (selectedFile) => {
    store.dispatch({
      type: "revision/setSelectedItem",
      paylaod: {
        obj: {},
      },
    });
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onloadend = async () => {
      try {
        const searchRegExp = new RegExp("&", "g");
        const xmlData = reader.result.replace(searchRegExp, "&amp;");
        const { maxOrder, result } = await new xml2js.Parser({
          explicitArray: false,
          emptyTag: { $: { id: "mockId" } },
        }).parseStringPromise(xmlData);
        const skeleton = await transformForStore(result);
        await creactDraft(store, skeleton, maxOrder);
      } catch (error) {
        addToast(error.message, {
          appearance: "error",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      }
    };
  };

  const addDraft = async () => {
    await handleClickedItem(addToast, store, {}, false, true, false);
  };

  return (
    <React.Fragment>
      <div id="Header" className="Header" data-testid="Header">
        <div className="file-actions d-flex flex-row ">
          <FileUploader setSelectedFile={handleSelectFile} />
          <button className="export-btn" onClick={onSave}>
            <Icon icon={"export"} size={15} />
            <span className="export">Export</span>
          </button>

          <button
            className="create-new-btn"
            onClick={() => {
              draftPresence ? OpenDialog("draft") : addDraft();
            }}
          >
            <Icon icon="add" size={15} color="#6f53a3" />
            <span className="create">Create</span>
          </button>
        </div>
      </div>
      {showDialog && (
        <ConfirmDialog
          confirmQuestion="Are you sure to discard the current draft?"
          itemName={"Draft of: V" + (lastVersion + 1)}
          show={showDialog}
          CloseDialog={CloseDialog}
          ConfirmAction={() =>
            confirmationHandler === "draft"
              ? addDraft()
              : changeHandler(confirmationHandler)
          }
        />
      )}
    </React.Fragment>
  );
}

export default Header;
