import { axiosProviderNode } from "../../../http/httpService";
import { services } from "../configVariables";

async function dachboardSignIn(username, password) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DASHBOARD_SIGNIN;

    const body = {
      dashboardUserName: username || "",
      dashboardUserPassword: password || "",
    };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    localStorage.setItem(
      "completedInstallation",
      JSON.stringify(res?.data?.result?.completedInstallation)
    );

    return res?.data;
  } catch (error) {
    console.error("Dashboard signIn error", error);

    if (error?.response?.status === 500) {
      return {
        status: false,
        message: "Cannot connect to Maxapps sync Server",
      };
    }

    throw error;
  }
}

export default dachboardSignIn;
