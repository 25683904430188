import getFileContent from "../getFileContent";

async function handleClickedItem(
  addToast,
  store,
  skeleton,
  filename,
  draftDisplay,
  readOnly
) {
  if (!store) {
    return;
  }

  const paylaod = {
    draftSkeleton: {},
    draftDisplay,
    skeleton,
    readOnly,
  };

  store.dispatch({
    type: "explorer/updateState",
    paylaod,
  });

  store.dispatch({
    type: "editor/dataAdded",
    paylaod: { data: {}, parent: [] },
  });

  const selectedItemPayload = filename
    ? {
        obj: { [filename]: true },
      }
    : { obj: {} };

  store.dispatch({
    type: "revision/setSelectedItem",
    paylaod: selectedItemPayload,
  });

  if (filename) {
    await getFileContent(store, filename, readOnly, addToast);
  }
}

export default handleClickedItem;
