import { setIn } from "lodash-redux-immutability";

import imxtools from "imxtools";

import isJSON from "../../modules/designer/services/helpers/isJSON";

const validation = (slices) => (store) => (next) => (action) => {
  let result = null;

  if (action.type === "validation") {
    try {
      const { addToast, updatedValues, objectValues, parent } = action.paylaod;

      let val = updatedValues;

      if (val.lastIndexOf(",") === val.length - 1) {
        val = val.substring(0, val.length - 1);
      }

      let json = `{${val}}`
        .replace(/([\w]+):/g, '"$1":')
        .replace(/=([[{])/g, ":$1");

      const isValidJson = isJSON(json);

      if (!isValidJson) {
        addToast("invalid json", {
          appearance: "error",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      } else {
        const obj = JSON.parse(json);

        const allChangedValues = obj?.["name"]
          ? { ...obj, name: obj?.["name"]?.toUpperCase() }
          : obj;

        const skeleton = store?.getState()?.entities?.explorer?.skeleton
          ? store?.getState()?.entities?.explorer?.skeleton
          : {};

        const key = imxtools.generateUniqueKey(parent[parent.length - 1]);

        let newData = setIn(skeleton, parent, {
          ...objectValues,
          ...allChangedValues,
          id: `imxComponent${key}`,
        });

        store.dispatch({
          type: "explorer/updateData",
          paylaod: {
            skeleton: { ...newData },
            readOnly: false,
          },
        });

        store.dispatch({
          type: "editor/updateData",
          paylaod: {
            data: { ...obj },
          },
        });

        addToast("Saved Successfully", {
          appearance: "success",
          autoDismissTimeout: 1000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      }
    } catch (error) {
      console.error("error--------------", error);
    }
  }

  result = next(action);

  return result;
};

export default validation;
