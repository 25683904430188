import "./signUp.scss";

import React from "react";

import SignUpForm from "./signup-form/signUpForm";

import logo from "../../../../assets/images/signUplogo.png";

function SignUp() {
  return (
    <div className="signUp  justify-content-center ">
      <div className="col-lg-7 col-md-12 signup-content">
        <div className="col-lg-6 col-md-4 signInImg text-center align-items-center">
          <img className="img-fluid" height={400} src={logo} alt="signImg" />
        </div>
        <div className="col-lg-6 col-md-8  signUpForm">
          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
