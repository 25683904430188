import "./index.scss";

import React from "react";

import EditorComp from "./EditorComp";

function Editor({ setUpdatedValues, data, readOnly }) {
  let initval =
    data &&
    Object.keys(data).reduce((acc, key) => {
      if (key === "id" || key?.toLowerCase() === "itemorder") {
        return acc;
      }
      if (typeof data[key] !== "object")
        acc +=
          "<span style='color:#6f53a3;font-size:16px; font-weight:600;'>" +
          [key] +
          ':</span><span id="someDivv" style="font-size:16px;font-weight:500;margin-left:10px;">"' +
          data[key] +
          '"</span>,<br>';
      return acc;
    }, "");

  const getValue = (value) => {
    let initValue = initval
      .replace(/<[^>]+>/g, "")
      .trim()
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&");

    let changedVal = value
      .replace(/<[^>]+>/g, "")
      .trim()
      .replace(/&nbsp;/g, "")
      .replace(/&amp;/g, "&");

    if (initValue !== changedVal) {
      setUpdatedValues(changedVal);
    }
  };

  return (
    <div className="Editor" id="Editor" data-testid="Editor">
      <div className="jodit-container ">
        <EditorComp
          contents={initval}
          getValue={getValue}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

export default Editor;
