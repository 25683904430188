import "./StandardLogin.scss";

import React, { useEffect, useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import BackButton from "../../global-components/back-button/BackButton";
import ForwardButton from "../../global-components/forward-button/ForwardButton";

import { FaEye, FaEyeSlash, FaTimes, FaCheck } from "react-icons/fa";

import { maximoLogin } from "../../../services/index";
import validateUrl from "./services/validateUrl";
import verifyUrlStatus from "./services/verifyUrlStatus";
import serverConfig from "./services/serverConfig";
import { useStore } from "react-redux";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Please enter maximo Username"),
  password: Yup.string().required("Please enter maximo Password"),
  url: Yup.string().required("Please enter maximo URL"),
  oslcUrl: Yup.string().required("Please enter Oslc URL"),
  maxRestUrl: Yup.string().required("Please enter MaxRest URL"),
});

const LoginSchemaWithApiKey = Yup.object().shape({
  username: Yup.string().required("Please enter maximo Username"),
  userApiKey: Yup.string().required("Please enter user api key"),
  url: Yup.string().required("Please enter maximo URL"),
  oslcUrl: Yup.string().required("Please enter Oslc URL"),
});

const fineUrl = (url = "") => {
  if (typeof url !== "string") {
    return;
  }
  let cleanUrl = url?.trim();
  if (!(url.startsWith("http://") || url.startsWith("https://"))) {
    return `http://${cleanUrl}`;
  } else {
    return cleanUrl;
  }
};

function BasicLogin(props) {
  const [useApiKey, setUseApiKey] = useState(false);

  const { handleNext, handlePrevious } = props;
  let store = useStore();

  const [loginMessage, setLoginMessage] = useState("");
  const method = "standard";
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [errorMaximoUrl, setErrorMaximoUrl] = useState(false);
  const [errorOslcUrl, setErrorOslcUrl] = useState(false);
  const [errorMaxRestUrl, setErrorMaxRestUrl] = useState(false);
  const [oslcStatus, setOslcStatus] = useState(undefined);
  const [maxRestStatus, setMaxRestStatus] = useState(undefined);
  const [address, setAddress] = useState("");
  const [protocol, setProtocol] = useState("");
  const [condition, setCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTogglePassword = () => {
    setVisiblePassword(!visiblePassword);
  };

  useEffect(() => {
    serverConfig({ setAddress, setProtocol });
  }, []);

  const verifyConditions = async (values) => {
    if (validateUrl(values?.url)) {
      const oslcResponse = await verifyUrlStatus(values?.oslcUrl, values);
      const errorOslcMessage = oslcResponse?.content?.message;
      setOslcStatus(oslcResponse?.content?.status);
      errorOslcMessage && setErrorOslcUrl(errorOslcMessage);
      if (!useApiKey) {
        const maxRestResponse = await verifyUrlStatus(
          `${values?.maxRestUrl}/rest/os`,
          values
        );
        const errorRestMessage = maxRestResponse?.content?.message;

        setMaxRestStatus(maxRestResponse?.content?.status);
        errorRestMessage && setErrorMaxRestUrl(errorRestMessage);
        setIsLoading(false);
        setCondition(
          oslcResponse?.content?.status && maxRestResponse?.content?.status
        );
        return;
      }

      setIsLoading(false);
      setCondition(oslcResponse?.content?.status);
    }
  };

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);
    setIsLoading(true);
    if (!condition) {
      verifyConditions(values);
    } else {
      const maximoUrl = fineUrl(values.url);
      const oslcUrl = values.oslcUrl;
      const maxRestUrl = values.maxRestUrl;
      const maxappsHost = `${protocol}://${address}`;

      maximoLogin(
        values.username,
        values.password,
        maximoUrl,
        oslcUrl,
        maxRestUrl,
        maxappsHost,
        method,
        null,
        values.userApiKey
      )
        .then((response) => {
          if (response?.content?.status?.toUpperCase() === "SUCCESS") {
            store.dispatch({
              type: "dashboard/updateMaximoLoginStatus",
              payload: {
                status: true,
              },
            });
            handleNext();
          } else {
            const errorMessage =
              response?.content && response?.content?.message !== ""
                ? response?.content?.message
                : response.message;
            setLoginMessage(errorMessage);
          }
          setSubmitting(false);
          setIsLoading(false);
        })
        .catch((error) => {
          setSubmitting(false);
          setIsLoading(false);

          setLoginMessage(error?.response?.data?.message);
        });
    }
  };

  return (
    <div
      id="StandardLogin"
      className="StandardLogin"
      data-testid="StandardLogin"
    >
      <Formik
        initialValues={{
          username: "",
          password: "",
          url: "",
          oslcUrl: "",
          maxRestUrl: "",
          userApiKey: "",
        }}
        validationSchema={useApiKey ? LoginSchemaWithApiKey : LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          setErrorMaximoUrl(!validateUrl(values.url));
          setErrorOslcUrl(!validateUrl(values.oslcUrl));
          setErrorMaxRestUrl(!validateUrl(values.maxRestUrl));
          if (useApiKey) {
            values.password = "";
          } else {
            values.userApiKey = "";
          }
          submitForm(values, setSubmitting);
        }}
      >
        {({ touched, errors, isSubmitting, values, setValues }) => (
          <div>
            <div className="col-lg-12">
              <p className="form-title">
                Connect MAXapps server to a MAXIMO Instance
              </p>
              <br />
            </div>

            <Form>
              <div className="form-group">
                <label
                  className={
                    values.url ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  Maximo url
                </label>
                <div className="verfied-input">
                  <Field
                    onInput={(e) => {
                      setCondition(false);
                      if (!validateUrl(e?.currentTarget?.value)) {
                        values.oslcUrl = ``;
                        values.maxRestUrl = ``;
                      } else {
                        const trimmedUrl =
                          e.currentTarget.value.slice(-1) === "/"
                            ? e.currentTarget.value.slice(
                                0,
                                e.currentTarget.value.lastIndexOf("/")
                              )
                            : e.currentTarget.value;

                        values.oslcUrl = useApiKey
                          ? `${trimmedUrl}/api`
                          : `${trimmedUrl}/oslc`;

                        values.maxRestUrl =
                          trimmedUrl.match(new RegExp("/", "g")) ||
                          [].length >= 3
                            ? `${trimmedUrl.slice(
                                0,
                                trimmedUrl.lastIndexOf("/")
                              )}/maxrest`
                            : `${trimmedUrl}/maxrest`;
                      }
                    }}
                    onBlur={(e) => {
                      setErrorMaximoUrl(!validateUrl(values?.url));
                    }}
                    data-testid="standard-url"
                    type="text"
                    name="url"
                    autoComplete="off"
                    className={`mt-2 form-control
                ${
                  (touched.url && errors.url) || errorMaximoUrl
                    ? "is-invalid"
                    : ""
                }`}
                  />
                  {errorMaximoUrl && (
                    <p className="invalid-feedback">Please enter valid URL !</p>
                  )}
                </div>
                <ErrorMessage
                  component="div"
                  name="url"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.username
                      ? "filled  input-label"
                      : "not-filled input-label"
                  }
                  htmlFor="url"
                >
                  Username
                </label>
                <Field
                  onInput={(e) => {
                    setCondition(false);
                  }}
                  data-testid="standard-username"
                  type="text"
                  name="username"
                  autoComplete="off"
                  className={`mt-2 form-control
  ${touched.username && errors.username ? "is-invalid" : ""}`}
                />
                <ErrorMessage
                  component="div"
                  name="username"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="useApiKeyCheckbox"
                  checked={useApiKey}
                  onChange={(e) => {
                    setUseApiKey(e.target.checked);
                    if (!validateUrl(values.url)) {
                      values.oslcUrl = ``;
                      values.maxRestUrl = ``;
                    } else {
                      const trimmedUrl =
                        values.url.slice(-1) === "/"
                          ? values.url.slice(0, values.url.lastIndexOf("/"))
                          : values.url;

                      values.oslcUrl = e.target.checked
                        ? `${trimmedUrl}/api`
                        : `${trimmedUrl}/oslc`;
                    }
                  }}
                />
                <label htmlFor="useApiKeyCheckbox">
                  Use API Key instead of username/password
                </label>
              </div>
              {useApiKey ? (
                <div className="form-group">
                  <label
                    className={
                      values.userApiKey ? "filled input-label" : " input-label"
                    }
                    htmlFor="userApiKey"
                  >
                    API Key
                  </label>
                  <Field
                    onInput={(e) => {
                      setCondition(false);
                    }}
                    data-testid="standard-userApiKey"
                    type="text"
                    name="userApiKey"
                    autoComplete="off"
                    className={`mt-2 form-control ${
                      touched.userApiKey && errors.userApiKey
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="userApiKey"
                    className="invalid-feedback"
                  />
                </div>
              ) : (
                <>
                  <div className="form-group">
                    <label
                      className={
                        values.password ? "filled  input-label" : " input-label"
                      }
                      htmlFor="url"
                    >
                      Password
                    </label>
                    <div className="password-input">
                      <Field
                        onInput={(e) => {
                          setCondition(false);
                        }}
                        style={{ paddingRight: "50px" }}
                        data-testid="standard-password"
                        show="true"
                        type={visiblePassword ? "text" : "password"}
                        name="password"
                        autoComplete="off"
                        className={`mt-2 form-control
  ${touched.password && errors.password ? "is-invalid" : ""}`}
                      />
                      <span
                        className="show-icon-container"
                        onClick={handleTogglePassword}
                      >
                        {visiblePassword ? (
                          <div className="show-icon">
                            <FaEyeSlash size={20} color="#4C36A5" />
                          </div>
                        ) : (
                          <div className="show-icon">
                            <FaEye size={20} color="#4C36A5" />
                          </div>
                        )}
                      </span>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>
                </>
              )}

              <div className="form-group">
                <label
                  className={
                    values.oslcUrl ? "filled  input-label" : " input-label"
                  }
                  htmlFor="url"
                >
                  Oslc url
                </label>
                <div className="verfied-input">
                  <Field
                    style={{ paddingRight: "60px" }}
                    onInput={(e) => {
                      setCondition(false);
                    }}
                    onBlur={(e) => {
                      setErrorOslcUrl(!validateUrl(values.oslcUrl));
                    }}
                    data-testid="standard-oslcUrl"
                    type="text"
                    name="oslcUrl"
                    autoComplete="off"
                    className={`mt-2 form-control
                      ${
                        (touched.oslcUrl && errors.oslcUrl) || errorOslcUrl
                          ? "is-invalid"
                          : ""
                      }`}
                  />
                  {errorOslcUrl && (
                    <p className="invalid-feedback">
                      {errorOslcUrl ? errorOslcUrl : "Please enter valid URL !"}
                    </p>
                  )}
                  <div className="show-icon-container">
                    {oslcStatus === undefined || !oslcStatus || errorOslcUrl ? (
                      <div className="show-icon">
                        <FaTimes size={26} color="#4C36A5" />
                      </div>
                    ) : (
                      <div className="show-icon">
                        <FaCheck size={26} color="#4C36A5" />
                      </div>
                    )}
                  </div>
                </div>
                <ErrorMessage
                  component="div"
                  name="oslcUrl"
                  className="invalid-feedback"
                />
              </div>
              {!useApiKey && (
                <div className="form-group">
                  <label
                    className={
                      values.maxRestUrl ? "filled  input-label" : " input-label"
                    }
                    htmlFor="url"
                  >
                    MaxRest url
                  </label>
                  <div className="verfied-input">
                    <Field
                      style={{ paddingRight: "60px" }}
                      onInput={(e) => {
                        setCondition(false);
                      }}
                      onBlur={(e) => {
                        setErrorMaxRestUrl(!validateUrl(values.maxRestUrl));
                      }}
                      data-testid="standard-maxRestUrl"
                      type="text"
                      name="maxRestUrl"
                      autoComplete="off"
                      className={`mt-2 form-control
                    ${
                      (touched.maxRestUrl && errors.maxRestUrl) ||
                      errorMaxRestUrl
                        ? "is-invalid"
                        : ""
                    }`}
                    />
                    {errorMaxRestUrl && (
                      <p className="invalid-feedback">
                        {errorMaxRestUrl
                          ? errorMaxRestUrl
                          : "Please enter valid URL !"}
                      </p>
                    )}
                    <div className="show-icon-container">
                      {maxRestStatus === undefined ||
                      !maxRestStatus ||
                      errorMaxRestUrl ? (
                        <div className="show-icon">
                          <FaTimes size={26} color="#4C36A5" />
                        </div>
                      ) : (
                        <div className="show-icon">
                          <FaCheck size={26} color="#4C36A5" />
                        </div>
                      )}
                    </div>
                  </div>
                  <ErrorMessage
                    component="div"
                    name="maxRestUrl"
                    className="invalid-feedback"
                  />
                </div>
              )}
              <div className="response-error">
                <span className="login-error">{loginMessage}</span>
              </div>
              {condition && (
                <>
                  <div className="verifiedUrlContainer">
                    <span className="show-url">{values?.oslcUrl}</span>
                    <div className="icon-url">
                      <FaCheck size={20} color="#059033" />
                    </div>
                  </div>

                  {!useApiKey && (
                    <div className="verifiedUrlContainer">
                      <span className="show-url">{`${values?.maxRestUrl}/rest/os`}</span>
                      <div className="icon-url">
                        <FaCheck size={20} color="#059033" />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="submit">
                <BackButton
                  type={"button"}
                  icon="chevron-left"
                  text="Cancel"
                  handleAction={() => handlePrevious()}
                  isActive={!isSubmitting}
                />
                <ForwardButton
                  type={"submit"}
                  text={!condition ? "Test urls" : "Connect"}
                  isActive={{
                    toLoad: isLoading,
                    disable: isLoading,
                  }}
                  icon={condition ? "checked" : "monitoring1"}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default BasicLogin;
