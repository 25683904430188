import { getMaximoConfig } from "../../modules/installer/services/index.js";

const maximoConfig = (slices) => (store) => (next) => async (action) => {
  let result = null;

  if (action.type === "checkMaximoConfig") {
    try {
      let config = null;
      let installation = null;

      store.dispatch({
        type: "dashboard/setLoading",
        payload: {
          isLoading: true,
        },
      });
      const response = await getMaximoConfig();
      if (!response.content || response?.status === "failure") {
        store.dispatch({
          type: "dashboard/setLoading",
          payload: {
            isLoading: false,
          },
        });
        store.dispatch({
          type: "dashboard/setGetCongfigError",
          payload: {
            getConfigError: response?.message ?? response,
          },
        });
      } else {
        config =
          Object.keys(response?.content?.dashboardUser).length === 0
            ? false
            : true;
        installation = response?.content?.dashboardUser?.completedInstallation
          ? true
          : false;
        store.dispatch({
          type: "dashboard/setLoading",
          payload: {
            isLoading: false,
          },
        });
        store.dispatch({
          type: "dashboard/updateAvailableConfig",
          payload: {
            isAvailableConfig: config,
          },
        });
        store.dispatch({
          type: "dashboard/updateCompletedInstallation",
          payload: {
            installationStatus: installation,
          },
        });

        store.dispatch({
          type: "dashboard/setGetCongfigError",
          payload: {
            getConfigError: null,
          },
        });
      }
    } catch (error) {
      store.dispatch({
        type: "dashboard/setLoading",
        payload: {
          isLoading: false,
        },
      });
      store.dispatch({
        type: "dashboard/setGetCongfigError",
        payload: {
          getConfigError: error?.message ?? error,
        },
      });
    }
  }
  result = next(action);

  return result;
};

export default maximoConfig;
