import React, { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";

import deleteIcons from "./services/deleteIcons";
import getAllIcons from "./services/getAllIcons";

function IconsListing({ iconsListing, setIconsListing }) {
  const { addToast } = useToasts();

  const [deleteDisabled, setDeleteDisabled] = useState(false);

  const handleDeleteIcon = (iconName = "") => {
    setDeleteDisabled(true);
    deleteIcons(iconName, addToast).then((deletedIconResponse) => {
      const filtredData = iconsListing?.filter(
        (oneIcon) => oneIcon?.iconName !== iconName
      );
      setIconsListing(filtredData);
      setDeleteDisabled(false);
    });
  };

  useEffect(() => {
    getAllIcons(addToast).then((allIconsResponse) =>
      setIconsListing(allIconsResponse)
    );
    return () => {};
  }, []);

  return (
    <>
      <h4 style={{ fontWeight: "bold", color: "#240046" }}>
        Custom Icons List (PNG images only)
      </h4>
      {iconsListing?.length !== 0 ? (
        iconsListing?.map((icon, index) => {
          return (
            <div key={index}>
              <p style={{ color: "#9d4edd" }}>{icon?.iconName}</p>
              <img
                style={{ height: 50, width: 50, objectFit: "contain" }}
                src={`/client/assets/mobile/icons/${icon?.iconName}`}
              />
              <button
                onClick={() => handleDeleteIcon(icon?.iconName)}
                disabled={deleteDisabled}
              >
                Delete
              </button>
            </div>
          );
        })
      ) : (
        <>No icons .......</>
      )}
    </>
  );
}

export default IconsListing;
