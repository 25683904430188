import "./InstalledItem.scss";

import React from "react";

function InstallerItem({ script }) {
  return (
    <div
      id="InstalledItem"
      className="InstalledItem"
      data-testid="InstalledItem"
    >
      <span className="file-name">{script?.name?.slice(0, -3)}</span>
      <span className={script?.status ? "file-status" : "file-status-failed"}>
        {script?.status ? "Installed" : "Not Installed"}
      </span>
    </div>
  );
}

export default InstallerItem;
