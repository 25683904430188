import "./InstallationVerification.scss";

import React, { useState, useEffect } from "react";

import ForwardButton from "../global-components/forward-button/ForwardButton";
import RetrySyncButton from "../global-components/retry-sync-button/RetrySyncButton";
import SyncForm from "../global-components/syncbackend-form/SyncForm";

import Loader from "../../../../assets/lottie-files/loader.json";
import Lottie from "react-lottie";

import { checkSyncBackend } from "../../services/index";
import __getMaxappsHost from "./services/__getMaxappsHost";

function InstallationVerification({ handleNext }) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [verifInstall, setVerifInstall] = useState(true);
  const [isVerifying, setIsVerifying] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [maxappsHost, setMaxappsHost] = useState(null);

  const checkVerif = (address = maxappsHost) => {
    setIsVerifying(true);
    checkSyncBackend(address)
      .then((response) => {
        if (response?.content?.status === "failure" || !response?.success) {
          setIsVerifying(false);
          setVerifInstall(false);
          setErrorMessage(response?.content?.message ?? response.message);
        } else {
          setVerifInstall(true);
          handleNext();
        }
      })
      .catch((error) => {
        setIsVerifying(false);
        setVerifInstall(false);
        setErrorMessage(error?.message);
      });
  };

  useEffect(() => {
    __getMaxappsHost(setMaxappsHost);
  }, []);

  return (
    <div
      id="InstallationVerification"
      className="InstallationVerification"
      data-testid="InstallationVerification"
    >
      <div className="verification-header">
        <div className="verification-title">
          <p className="title">Post MAXapps installation verification</p>
        </div>
      </div>
      <div className="verification-footer-btn">
        {isVerifying || !maxappsHost ? (
          <div className="verif-loader">
            <Lottie
              isClickToPauseDisabled={true}
              options={LoadingOptions}
              height={68}
              width={68}
              style={{
                cursor: "auto",
              }}
            />
          </div>
        ) : (
          <div className="verif-next-btn">
            {verifInstall ? (
              <RetrySyncButton
                type="submit"
                text="Next"
                isActive={true}
                icon="chevron-right"
                handleAction={checkVerif}
              />
            ) : (
              !verifInstall && (
                <ForwardButton
                  type="submit"
                  text="Skip"
                  isActive={{ toLoad: false, disable: false }}
                  icon="chevron-right"
                  handleAction={handleNext}
                />
              )
            )}
          </div>
        )}
        <div className="sync-error-message">
          <span>{errorMessage ? errorMessage : ""}</span>
        </div>
        {errorMessage && (
          <SyncForm actionSubmit={checkVerif} isChecking={!isVerifying} />
        )}
      </div>
    </div>
  );
}

export default InstallationVerification;
