import React, { useState, useEffect } from "react";

function UninstallProgressBar(props) {
  const { bgcolor } = props;

  const [value, setValue] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setValue((oldValue) => {
        const newValue = oldValue + 1;
        if (newValue === 110) {
          setValue(0);
        }
        return newValue;
      });
    }, 25);
    return () => {
      setValue(0);
    };
  }, []);

  const containerStyles = {
    display: "flex",
    height: 10,
    width: "100%",
    backgroundColor: "#e0e0de",
  };

  const fillerStyles = {
    height: "100%",
    width: `${value}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div
      id="UninstallProgressBar"
      className="UninstallProgressBar"
      data-testid="UninstallProgressBar"
    >
      <div style={containerStyles}>
        <div style={fillerStyles}></div>
      </div>
    </div>
  );
}

export default UninstallProgressBar;
