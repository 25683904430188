import getFileContent from "../getFileContent";

async function handleReadContentVersion(addToast, store, filename) {
  if (!store) {
    return;
  }

  const draftSkeleton = store?.getState()?.entities?.explorer?.skeleton;

  store.dispatch({
    type: "explorer/addDraftSkeleton",
    paylaod: {
      skeleton: draftSkeleton,
    },
  });

  store.dispatch({
    type: "editor/dataAdded",
    paylaod: { data: {}, parent: [] },
  });

  const paylaod = filename ? { obj: { [filename]: true } } : {};

  store.dispatch({
    type: "revision/setSelectedItem",
    paylaod,
  });

  await getFileContent(store, filename, true, addToast);
}

export default handleReadContentVersion;
