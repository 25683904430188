async function creactDraft(store, skeleton, order) {
  if (!store || !skeleton || !order) {
    return;
  }

  const paylaod = {
    draftSkeleton: skeleton,
    draftDisplay: true,
    skeleton,
    readOnly: false,
    order,
  };

  store.dispatch({
    type: "explorer/incrementOrder",
    paylaod: { order },
  });

  store.dispatch({
    type: "explorer/updateState",
    paylaod,
  });
}

export default creactDraft;
