import "./index.scss";

import React, { useRef, useState } from "react";

import { useStore, useSelector } from "react-redux";

import { useToasts } from "react-toast-notifications";

import ConfirmDialog from "../../confirmation-dialog";

import Icon from "../../../icon";

import editXmlAlias from "../../../../services/editXmlAlias";
import handleReadContentVersion from "../../../../services/helpers/handleReadContentVersion";
import handleEditContentVersion from "../../../../services/helpers/handleEditContentVersion";
import deleteVersion from "../../../../services/deleteVersion";

function Version({ data, versionsData, disable }) {
  let store = useStore();

  const { addToast } = useToasts();

  const [showDialog, setShowDialog] = useState(false);
  const [deleteConfirmTrigger, setDeleteConfirmTrigger] = useState(true);
  const [aliasEditMode, setAliasEditMode] = useState();

  const draftPresence = store.getState()?.entities?.explorer?.draftDisplay;

  const lastVersion = store.getState()?.entities?.revision?.lastVersion;

  const deleteConfirmation = {
    confirmQuestion: "Are you sure to delete this version?",
    versionName:
      data?.version + "-" + data?.xmlAlias ?? "My Alias" + "-" + data?.release,
  };

  const editConfirmation = {
    confirmQuestion: "Are you sure to discard the current draft?",
    draftName:
      "Draft" + data?.xmlAlias ?? "My Alias" + "of: V" + (lastVersion + 1),
  };

  const CloseDialog = () => {
    setShowDialog(false);
    setDeleteConfirmTrigger(true);
  };

  const OpenDialog = () => setShowDialog(true);

  const handleEditVersion = async () => {
    await handleEditContentVersion(
      addToast,
      store,
      data?.filename,
      data?.maxOrder
    );
    setDeleteConfirmTrigger(true);
  };

  const handleReadContent = async () => {
    handleReadContentVersion(addToast, store, data?.filename);
  };

  const handleEditAlias = async () => {
    try {
      const xmlAlias =
        inputRef?.current?.value === "" ? "My Alias" : inputRef?.current?.value;
      await editXmlAlias(store, data?.filename, xmlAlias, addToast);
    } catch (error) {
      addToast("Server is unreachable!", {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  };

  const handleDelete = async () => {
    const version =
      data?.version === `V${versionsData[0]?.version}`
        ? versionsData[0]?.version - 1
        : false;
    await deleteVersion(store, data?.filename, addToast, version);
  };

  let edited = useSelector((state) =>
    state?.entities?.revision?.itemSelected
      ? state?.entities?.revision.itemSelected
      : {}
  );

  const inputRef = useRef(null);

  return (
    <div id="Version" className="Version">
      <div className="version-compo">
        <div
          className={
            edited[data.filename]
              ? "version-selected version-wrapper"
              : "version-wrapper"
          }
        >
          <button
            className={edited[data.filename] ? "selected-btn" : "version-btn"}
            onClick={handleReadContent}
            disabled={disable}
          >
            <div>
              {aliasEditMode ? (
                <>
                  <span
                    className={
                      edited[data.filename]
                        ? "versionselected-name"
                        : "version-name"
                    }
                  >
                    {data?.version}
                  </span>
                  <input
                    className="input-draft"
                    ref={inputRef}
                    type="text"
                    placeholder={"Enter Alias"}
                    defaultValue={data?.xmlAlias || ""}
                  />
                  <span
                    className={
                      edited[data.filename]
                        ? "versionselected-name"
                        : "version-name"
                    }
                  >
                    {data?.release}
                  </span>
                </>
              ) : (
                <span
                  className={
                    edited[data.filename]
                      ? "versionselected-name"
                      : "version-name"
                  }
                >
                  {data?.version} - {data?.xmlAlias} - {data?.release}
                </span>
              )}
            </div>
          </button>
          <button
            className={"edit-version-btn"}
            onClick={() => {
              if (draftPresence) {
                OpenDialog();
                setDeleteConfirmTrigger(false);
              } else {
                handleEditVersion();
              }
            }}
          >
            <span>Edit</span>
          </button>
        </div>
        <button
          className="cancel-draft-btn"
          onClick={() => OpenDialog()}
          disabled={disable}
        >
          <Icon icon="rubbish-bin_solid" size={25} />
        </button>

        {aliasEditMode ? (
          <button
            className="cancel-draft-btn"
            onClick={() => {
              handleEditAlias();
              setAliasEditMode(false);
            }}
            disabled={disable}
          >
            <Icon icon="check" size={25} />
          </button>
        ) : (
          <button
            className="cancel-draft-btn"
            onClick={() => {
              setAliasEditMode(true);
            }}
            disabled={disable}
          >
            <Icon icon="edit" size={25} />
          </button>
        )}
      </div>
      <ConfirmDialog
        confirmQuestion={
          deleteConfirmTrigger
            ? deleteConfirmation.confirmQuestion
            : editConfirmation.confirmQuestion
        }
        itemName={
          deleteConfirmTrigger
            ? deleteConfirmation.versionName
            : editConfirmation.draftName
        }
        show={showDialog}
        CloseDialog={CloseDialog}
        ConfirmAction={deleteConfirmTrigger ? handleDelete : handleEditVersion}
      />
    </div>
  );
}

export default Version;
