import "./FileUploader.scss";

import React from "react";

import Icon from "../../icon";

function FileUploader(props) {
  const { setSelectedFile } = props;

  let hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event = null) => {
    if (!event) return;
    const fileUploaded = event?.target?.files[0] || null;
    fileUploaded && setSelectedFile(fileUploaded);
    hiddenFileInput.current.value = null;
  };

  return (
    <div className="FileUploader" id="FileUploader" data-testid="FileUploader">
      <button className="file-button" onClick={handleClick}>
        <Icon icon={"import"} size={15} />
        <span className="import">Import</span>
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        className="file-input"
        accept=".xml"
      />
    </div>
  );
}

export default FileUploader;
