import getMaximoConfig from "../../../../../installer/services/getConfig/modules/getMaximoConfig";

function __getStatsThresholdInfos(setMailsInfo = () => {}) {
  getMaximoConfig()
    .then((response) => {
      setMailsInfo({
        mailsSender:
          response.content?.criticalLevelSettings?.thresholdNotificationEmails,
        statsThreshold: response.content?.criticalLevelSettings?.statsThreshold,
      });
    })
    .catch((error) => {
      console.error("Error=====>", error);
    });
}

export default __getStatsThresholdInfos;
