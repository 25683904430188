import { axiosProviderNode } from "../../http/httpService";

async function updateMaintenance(update = false) {
  try {
    const transactionNodeService = "maxappsDesigner/updateMaintenance";

    const res = await axiosProviderNode.post(transactionNodeService, {
      update,
    });
    return res?.data;
  } catch (error) {
    return false;
  }
}

export default updateMaintenance;
