import "./index.scss";

import React, { useState } from "react";

import Server from "./server";
import Device from "./device";

import { mockData } from "./mock";

import Icon from "../../../designer/components/icon";
import Sidebar from "../monitor-sidebar";
import DashboardApp from "../../../dashboard/Dashboard";

function Performances(props) {
  const { data, serverIcon = "monitoring", deviceIcon = "monitoring" } = props;
  const { serverData = mockData.serverData } = data?.performances || {};
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <DashboardApp>
      <Sidebar>
        <div className="PerformancesIndex p-4">
          <div className="bloc-tabs">
            <button
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(1)}
            >
              <Icon
                size={40}
                className="p-2"
                color="#6957b5"
                icon={serverIcon}
              />
              Server
            </button>
            <button
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              <Icon
                size={40}
                className="p-2"
                color="#6957b5"
                icon={deviceIcon}
              />
              Device
            </button>
          </div>

          <div className="content-tabs">
            <div
              className={
                toggleState === 1 ? "content  active-content" : "content"
              }
            >
              <Server serverData={serverData} />
            </div>

            <div
              className={
                toggleState === 2 ? "content  active-content" : "content"
              }
            >
              <Device />
            </div>
          </div>
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export default Performances;
