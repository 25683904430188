import { axiosProviderNode } from "../../http/httpService";
import { services } from "./configVariables";

async function getServerConfig() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_SERVER_CONFIG;

    const res = await axiosProviderNode.get(transactionNodeService);
    return res?.data;
  } catch (error) {
    console.error("error getServerConfig== ", error);

    return error;
  }
}

export default getServerConfig;
