function tryParseJSONObject(jsonString = "") {
  try {
    if (jsonString && typeof jsonString === "object") {
      return jsonString;
    }
    const ParsedValue = JSON.parse(jsonString);
    return ParsedValue;
  } catch (e) {
    return jsonString;
  }
}

export default tryParseJSONObject;
