import "./Welcome.scss";

import React from "react";

import ForwardButton from "../global-components/forward-button/ForwardButton";

function Welcome({ handleNext }) {
  return (
    <div id="Welcome" className="Welcome" data-testid="Welcome">
      <div className="welcome-content">
        <div className="welcome-title">
          <p className="welcome-title-text">Start connecting MAXapps server</p>
        </div>
        <div className="welcome-title">
          <p className="welcome-title-text">to Maximo instance</p>
        </div>
        <div className="start-btn">
          <ForwardButton
            text="Connect"
            isActive={{ toLoad: false, disable: false }}
            icon="checked"
            handleAction={handleNext}
          />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
