import "./RetrySync.scss";

import React, { useState } from "react";

import RetrySyncButton from "../../global-components/retry-sync-button/RetrySyncButton";
import SyncForm from "../../global-components/syncbackend-form/SyncForm";
import checkVerif from "./services/checkVerif";

function RetrySync(props) {
  const { setSyncBackendStatus, maxappsHost } = props;
  const [syncError, sertSyncEror] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const handleRetrySync = (address = maxappsHost) => {
    checkVerif(setSyncBackendStatus, sertSyncEror, setIsActive, address);
  };
  return (
    <div className="RetrySync">
      {syncError ? (
        <React.Fragment>
          <p className="retry-sync-error">{syncError}</p>
          <SyncForm actionSubmit={handleRetrySync} isChecking={isActive} />
        </React.Fragment>
      ) : (
        <RetrySyncButton
          text="Retry SyncBackend"
          icon="refresh"
          handleAction={handleRetrySync}
          isActive={isActive}
        />
      )}
    </div>
  );
}

export default RetrySync;
