import "./index.scss";

import React from "react";

import { Card } from "react-bootstrap";

function UsersCard(props) {
  const { value, description } = props;
  return (
    <div className="usersCard">
      <Card>
        <Card.Body>
          <div className="px-4">
            <h1>{value}</h1>
          </div>
          <div className="px-4 center">
            <h6>{description}</h6>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UsersCard;
