import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "./services";

async function setenvConfDataWithParams(params = {}) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_SET_ENVCONF;

    const res = await axiosProviderNode.post(transactionNodeService, params);
    return res?.data;
  } catch (error) {
    throw error;
  }
}
export default setenvConfDataWithParams;
