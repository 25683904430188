import React, { useEffect } from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

import ReactJson from "react-json-view";

import lodash from "lodash";

import axios from "axios";

import { io } from "socket.io-client";

import { BsHourglass } from "react-icons/bs";

import tryParseJSONObject from "../modules/tryParseJSONObject";
import tryStringifyJSONObject from "../modules/tryStringifyJSONObject";
import { getMaximoConfig } from "../../installer/services/index";
import DashboardApp from "../../dashboard/Dashboard";
import Sidebar from "./monitor-sidebar";
const fileReadersSockets = new Map();

function getLogFoldersListing(callBack = () => {}) {
  axios
    .get("/sys/getLogs?json=true")
    .then(({ data } = {}) => {
      const { folders } = data;
      !Object.keys(folders).length && alert("no folders under ./log");
      callBack(folders ? folders : []);
    })
    .catch((error) => {
      console.error("getLogFoldersListing error", error);
      alert("LogFoldersListing error");
      callBack([]);
    });
}

function LogFilesSelectList(props) {
  const { logFoldersListing = {}, logReaderId, setLines } = props;
  const [selectedValue, setSelectedValue] = React.useState(false);

  return {
    Render: () => {
      return (
        <FormControl
          sx={{
            minWidth: 150,
          }}
        >
          <InputLabel htmlFor={`logReaderId-${logReaderId}`}>
            select log file
          </InputLabel>
          <Select
            defaultValue=""
            id={`logReaderId-${logReaderId}`}
            label="select log file"
            onChange={({ target }) => {
              if (target?.value) {
                setSelectedValue(target.value);
                setLines([]);
              }
            }}
          >
            <MenuItem value="" disabled>
              <em>None</em>
            </MenuItem>

            {Object.entries(logFoldersListing || {}).map(
              ([logFolderId, logFolderContent = []], logFolderIndex) => {
                return [logFolderId, ...logFolderContent].map(
                  (folderItem, folderItemIndex) => {
                    if (folderItemIndex === 0) {
                      return (
                        <ListSubheader key={logFolderIndex} disabled>
                          {folderItem}
                        </ListSubheader>
                      );
                    }

                    const MenuItemValue = `/${logFolderId}/${folderItem}`;
                    return (
                      <MenuItem value={MenuItemValue} key={folderItemIndex}>
                        {MenuItemValue}
                      </MenuItem>
                    );
                  }
                );
              }
            )}
          </Select>
        </FormControl>
      );
    },
    selectedValue,
  };
}

function startWatchSocket(
  watchFile = {},
  eventsCallBack = () => {},
  serverURL = "http://localhost:4104"
) {
  const { nameSpace, fileUri = undefined, action } = watchFile;
  const socket = io(`${serverURL}${nameSpace}`, {
    query: {
      action: action,
      fileUri: fileUri,
    },
    transports: ["polling", "websocket"],
    timeout: 5000,
    rejectUnauthorized: false,
  });
  function encapsulatePayload(reason = "event", data = {}) {
    return Object.assign(
      {
        ...data,
      },
      {
        reason,
        connected: socket.connected,
      }
    );
  }

  socket.on("connect", () => {
    eventsCallBack({
      reason: "io client connect",
      connected: socket.connected,
      on: "connect",
    });
  });
  socket.on("close", (reason) => {
    socket.disconnect();
    eventsCallBack(encapsulatePayload(reason));
  });
  socket.on("disconnect", (reason) => {
    eventsCallBack(encapsulatePayload(reason));
  });
  socket.on("eventsCallBack", (data, reason = "event") => {
    eventsCallBack(encapsulatePayload(reason, data));
  });
  socket.on("connect_error", (error, reason = "error") => {
    socket.disconnect();
    eventsCallBack(encapsulatePayload(reason));
  });

  return socket;
}

function startWatchingFile(
  selectedValue,
  logReaderId,
  OutputStream,
  serverURL
) {
  const watchFile = {
    nameSpace: "/readLog",
    fileUri: selectedValue.replace("/root", ""),
    action: "readAllFileAndWatch",
    status: "stopped",
    socket: false,
    displayExtra: true,
  };

  function handleStopWatchFile(event) {
    watchFile.socket && watchFile.socket.disconnect();
    Object.assign(watchFile, {
      status: "stopped",
      socket: false,
    });
  }
  function eventsCallBack(payload) {
    const { connected } = payload;
    if (!connected) {
      handleStopWatchFile();
    }
    OutputStream({
      watchFile,
      eventsStream: payload,
    });
  }

  const socket = startWatchSocket(watchFile, eventsCallBack, serverURL);
  socket.on("connect", () => {
    Object.assign(watchFile, {
      status: "running",
      socket,
    });

    fileReadersSockets.set(logReaderId, watchFile);
  });

  function stopWatching() {
    if (watchFile && watchFile.socket) {
      watchFile.socket.disconnect();
      fileReadersSockets.delete(logReaderId);
    }
  }

  return {
    stopWatching,
    watchFile,
  };
}

function LogReader(props) {
  const { logReaderId, serverURL } = props;
  const [logFoldersListing, setLogFoldersListing] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [stopWatching, setStopWatching] = React.useState(false);

  const [lines, setLines] = React.useState([]);
  const [collapsed, setCollapsed] = React.useState(true);

  const { Render: RenderLogFilesSelectList, selectedValue } =
    LogFilesSelectList({
      logReaderId,
      logFoldersListing,
      setLines,
    });

  const OutputStream = (payload) => {
    const { eventsStream } = payload;
    const { data } = eventsStream || {};
    const { lineArray = [] } = data || {};
    lineArray?.forEach((element) => {
      const line = tryParseJSONObject(element);
      setLines((lastState) => [line, ...lastState]);
    });
  };

  const TriggerStartWatchingFile = () => {
    setLines([0]);
    const { stopWatching: _stopWatching } = startWatchingFile(
      selectedValue,
      logReaderId,
      OutputStream,
      serverURL
    );
    setStopWatching({ callBack: _stopWatching });
  };
  React.useEffect(() => {
    if (!logFoldersListing) {
      getLogFoldersListing((folders) => {
        setLogFoldersListing(folders);
      });
    }
    return () => {};
  }, []);

  const disPlayLogs = () => {
    return lines?.map((line, index) => {
      if (typeof line !== "object") {
        return <div key={index}> {line} </div>;
      }
      const { timestamp, level, message } = line;
      const messageString = message ? tryStringifyJSONObject(message) : "";

      const levelString = level ? tryStringifyJSONObject(level) : "";
      const timestampString = !isNaN(Date.parse(timestamp))
        ? new Date(timestamp).toLocaleString()
        : tryStringifyJSONObject(timestamp);

      const extra = lodash.omit(line, ["timestamp", "level", "message"]);
      return (
        <div key={index}>
          <span title="timestamp" timestamp="${timestamp}">
            {timestampString}
          </span>
          <span title="level" level="${level}">
            | {levelString} |
          </span>

          <span title="message" message="${message}">
            {messageString}
          </span>

          {checked && Object.keys(extra).length && (
            <ReactJson
              name={false}
              displayDataTypes={false}
              collapsed={collapsed}
              src={extra}
            />
          )}
        </div>
      );
    });
  };
  return (
    <div>
      {(logFoldersListing && (
        <div>
          {RenderLogFilesSelectList()}

          {selectedValue && (
            <React.Fragment>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                }
                label="display extra"
              />
              <Button onClick={TriggerStartWatchingFile}>start watching</Button>
              <Button
                onClick={() => {
                  stopWatching?.callBack instanceof Function &&
                    stopWatching.callBack();
                }}
                disabled={!stopWatching?.callBack}
              >
                stop watching
              </Button>

              <Button onClick={() => setLines([])}>clear reader</Button>
              <Button onClick={() => setCollapsed(!collapsed)}>
                expand/collapse all
              </Button>
              {lines.length && disPlayLogs()}
            </React.Fragment>
          )}
        </div>
      )) || <BsHourglass />}
      {props?.children || null}
    </div>
  );
}

function LogsReader(props) {
  const [logReaders, setLogReaders] = React.useState([]);
  const [serverURL, setserverURL] = React.useState("");
  const removeLogReader = (logReaderId) => () => {
    setLogReaders(logReaders.filter((loggerId) => loggerId !== logReaderId));
  };
  useEffect(() => {
    (async () => {
      const { socketAddress, protocol } = await getMaximoConfig().then(
        (res) => res?.content?.server
      );
      const { hostname: windowaddress } = window.location;

      setserverURL(
        `${socketAddress ? `${protocol}://${socketAddress}` : windowaddress}`
      );
    })();
  }, []);

  return (
    <DashboardApp>
      <Sidebar>
        <div>
          <div>
            <Button
              onClick={() => {
                setLogReaders([...logReaders, new Date().getTime()]);
              }}
            >
              read log file
            </Button>
          </div>
          <div>
            {logReaders.map((logReaderId) => {
              return (
                <div key={logReaderId}>
                  <LogReader serverURL={serverURL} {...{ logReaderId }}>
                    {/* <div>{logReaderId}</div> */}
                    <Button
                      onClick={removeLogReader(logReaderId)}
                      size="small"
                      //   color="danger"
                    >
                      close reader
                    </Button>
                  </LogReader>
                </div>
              );
            })}
          </div>
          {props?.children || null}
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export { LogsReader };
