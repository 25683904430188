import "./ReinstallPrerequest.scss";

import React, { useState } from "react";

import ForwardButton from "../forward-button/ForwardButton";
import BackButton from "../back-button/BackButton";
import AlertDialog from "../alert-dialog/AlertDialog";
import UninstallProgressBar from "../uninstall-progress-bar/UninstallProgressBar";
import DetailsListHeader from "./details-list-header/DetailsListHeader";
import PopUpList from "../pop-up-list/PopUpList";

import { scriptInstallation } from "../../../services/index";
import uninstallAllFromMaximo from "./services/uninstallAllFromMaximo";

function ReinstallPrerequest({ handleNext, handlePrevious }) {
  const [toClear, setToClear] = useState(false);
  const [finish, setFinish] = useState(false);
  const [failedToClear, setFailedToClear] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAllUninstalled, setIsAllUninstalled] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDetails, setShowErrorDetails] = useState(false);
  const [response, setResponse] = useState({});
  const [checkVersions, setcheckVersions] = useState(true);
  const [failedScripts, setFailedScripts] = useState([]);

  const uninstallMessage =
    "Do you want to uninstall MAXapps related configuration from Maximo?";
  const isUnistallingMessage = "Uninstalling MAXapps";

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => setShowDialog(true);

  const handleShowDetails = () => setShowErrorDetails(!showErrorDetails);

  const countFailedScript = (allScripts) => {
    let failedNumber = 0;
    let scripts = [];
    allScripts?.forEach((item) => {
      if (item.status === "failure") {
        failedNumber++;
        scripts.push(item);
      }
    });
    if (failedNumber > 0) {
      setIsAllUninstalled(false);
      setFailedScripts(scripts);
    }
  };

  const checkInstallerTools = async (response) => {
    setFailedToClear(false);
    setToClear(true);

    scriptInstallation(response?.content?.script, true)
      .then((res) => {
        if (res?.success) {
          uninstallAll();
        } else {
          const errorMessage =
            res?.content?.message === "" ? res?.message : res?.content?.message;
          setToClear(false);
          setResponse(res);
          setErrorMessage(errorMessage);
          setFailedToClear(true);
        }
      })
      .catch((error) => {
        setToClear(false);
        setResponse({});

        setErrorMessage(error.message);
        setFailedToClear(true);
      });
  };

  const uninstallAll = () => {
    setFailedToClear(false);
    setToClear(true);
    uninstallAllFromMaximo(checkVersions)
      .then((response) => {
        if (
          response?.success &&
          response?.content?.status?.toUpperCase() === "SUCCESS"
        ) {
          countFailedScript(response?.content?.result);
          setFinish(true);
          setToClear(false);
        } else {
          const errorMessage =
            response?.content?.message === ""
              ? response?.message
              : response?.content?.message;
          setToClear(false);
          setResponse(response);
          setErrorMessage(errorMessage);
          setFailedToClear(true);
        }
      })
      .catch((error) => {
        setToClear(false);
        setErrorMessage(error.message);
        setFailedToClear(true);
      });
  };

  return (
    <div
      id="ReinstallPrerequest"
      className="ReinstallPrerequest"
      data-testid="ReinstallPrerequest"
    >
      <div className="reinstall-header">
        <div className="reinstall-title">
          <p className="title">A MAXapps server has been already installed</p>
        </div>
        <div className="reinstall-title">
          <p className="title"> on this Maximo</p>
        </div>
      </div>
      <div className="reinstall-footer-btn">
        <BackButton
          type="submit"
          text="Cancel"
          isActive={true}
          icon="chevron-left"
          handleAction={() => handlePrevious()}
        />
        <ForwardButton
          type="submit"
          text="Proceed anyway"
          isActive={{ toLoad: false, disable: false }}
          icon="chevron-right"
          handleAction={openDialog}
        />
      </div>
      <AlertDialog
        dialogHeader={
          !finish && toClear && !failedToClear
            ? isUnistallingMessage
            : !finish
            ? uninstallMessage
            : ""
        }
        show={showDialog}
        CloseDialog={closeDialog}
      >
        <React.Fragment>
          {!finish && !toClear && !failedToClear && (
            <React.Fragment>
              <div className="dialog-option">
                <input
                  className="dialog-input"
                  type="checkbox"
                  id="checkVersions"
                  name="checkVersions"
                  value={checkVersions}
                  checked={checkVersions}
                  onChange={() => {
                    setcheckVersions(!checkVersions);
                  }}
                />
                check files versions
              </div>
              <div className="confirm-uninstall">
                <button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    closeDialog();
                  }}
                  data-testid="No"
                >
                  <span className="dialog-btn-text">No</span>
                </button>
                <button
                  className="confirm-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    uninstallAll();
                  }}
                  data-testid="Yes"
                >
                  <span className="dialog-btn-text">Yes</span>
                </button>
              </div>
            </React.Fragment>
          )}
          {!finish && toClear && !failedToClear && (
            <div className="uninstall-progress">
              <UninstallProgressBar
                textColor="#4c36a5"
                bgcolor="#4c36a5"
                completed="50"
              />
            </div>
          )}
          {failedToClear && !toClear && !finish && (
            <div className="retry-uninstall">
              <div className="retry-message">{errorMessage}</div>
              <div className="retry-grid">
                <div className="retry">
                  <button
                    className="retry-btn"
                    onClick={(e) => {
                      e.stopPropagation();

                      if (
                        response &&
                        response?.content?.status?.toString()?.toLowerCase() ===
                          "failure" &&
                        response?.content?.script
                      ) {
                        checkInstallerTools(response);
                        return;
                      }
                      uninstallAll();
                    }}
                    data-testid="Yes"
                  >
                    <span className="dialog-btn-text">Retry</span>
                  </button>
                </div>
                {!response?.content?.script && (
                  <div className="skip">
                    <button
                      className="skip-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNext();
                      }}
                      data-testid="Yes"
                    >
                      <span className="dialog-btn-text">Skip</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {finish && (
            <div className="success-uninstall">
              {isAllUninstalled ? (
                <div className="success-title">
                  MAXapps has been uninstalled succesfully
                </div>
              ) : !showErrorDetails ? (
                <DetailsListHeader
                  action={handleShowDetails}
                  icon="chevron-up"
                  title="Scripts are not completely uninstalled from Maximo"
                />
              ) : (
                <React.Fragment>
                  <DetailsListHeader
                    action={handleShowDetails}
                    icon="chevron-down"
                    title="Some scripts haven't been uninstalled from Maximo"
                  />
                  <PopUpList scripts={failedScripts} />
                </React.Fragment>
              )}
              <div className="finish">
                <button
                  className="finish-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNext();
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      </AlertDialog>
    </div>
  );
}

export default ReinstallPrerequest;
