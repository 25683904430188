import "./index.scss";

import React from "react";

import { useStore, batch } from "react-redux";

import { VscChromeClose } from "react-icons/vsc";

import { setIn, getIn } from "lodash-redux-immutability";

import imxtools from "imxtools";

import Icon from "../../../../icon";

import { obj } from "../../../../../services/common";

function AddNewSection({
  data,
  displayAddNew = () => {},
  parentDetail,
  setCurentParent = () => {},
}) {
  let item = parentDetail?.item;
  let parent = parentDetail?.parent;
  let subItems = obj[item];
  let store = useStore();

  const handleAddItem = (key) => {
    const state = getIn(data, [...parent, key]);
    const id = imxtools.generateUniqueKey(parent?.[parent.length - 1] ?? key);
    const index = store.getState()?.entities?.explorer?.maxOrder
      ? store.getState()?.entities?.explorer?.maxOrder
      : 0;
    const newElementContent = {
      id: `imxComponent${id}`,
      itemOrder: index,
    };
    const dataToInsert = !state
      ? newElementContent
      : [...imxtools.toArray(state), newElementContent];
    const newData = setIn(data, [...parent, key], dataToInsert);
    const parentToInsert = dataToInsert?.length?.toString()
      ? [...parent, key, dataToInsert.length.toString()]
      : [...parent, key];
    setCurentParent({
      item: key,
      parent: parentToInsert,
    });

    batch(() => {
      store.dispatch({
        type: "explorer/updateData",
        paylaod: {
          skeleton: { ...newData },
          readOnly: false,
        },
      });
      store.dispatch({
        type: "explorer/incrementOrder",
        paylaod: { order: index + 1 },
      });
    });
    store.dispatch({
      type: "editor/dataAdded",
      paylaod: {
        data: {},
        parent: parentToInsert,
      },
    });
  };

  return (
    <div
      id="AddNewSection"
      className="AddNewSection"
      data-testid="AddNewSection"
    >
      <div className="add-new-label">
        <span className="add-new">Add new</span>
        <button className="close-new-btn" onClick={() => displayAddNew(false)}>
          <VscChromeClose size={25} color="#aaaaaa" />
        </button>
      </div>
      <hr />

      <div className="add-new-buttons">
        {subItems &&
          Object.keys(subItems).map((key, index) => {
            return (
              <div key={index} className="new-application">
                <button
                  className="new-app-btn"
                  onClick={() => {
                    displayAddNew(false);
                    handleAddItem(key);
                  }}
                >
                  <span>
                    <Icon
                      icon={subItems?.[key]?.icon}
                      size={20}
                      color="#6f53a3"
                    />
                  </span>
                  <span>{subItems?.[key]?.title}</span>
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default AddNewSection;
