import "./signIn.scss";

import React from "react";

import SignInForm from "./signin-form/signInForm";

import logo from "../../../../assets/images/signIn.png";

function SignIn() {
  return (
    <div className="signIn justify-content-center">
      <div className="col-lg-7 col-md-12 signin-content">
        <div className="col-lg-6 col-md-4 signInImg text-center align-items-center">
          <img className="img-fluid" height={400} src={logo} alt="signImg" />
        </div>
        <div className="col-lg-6 col-md-8  signInForm">
          <SignInForm />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
