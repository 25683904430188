import "./Dashboard.scss";

import React, { useState } from "react";

import { FaBars } from "react-icons/fa";

import DahsboardSideBar from "./dashboard-sidebar";
import DashboardHeader from "./dashboard-header";

function Dashboard(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className="Dashboard" id="Dashboard" data-testid="Dashboard">
      <DashboardHeader />
      <div className="dashboard-wrapper">
        <DahsboardSideBar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <div className="dashboard-body">{props.children}</div>
      </div>
    </div>
  );
}

export default Dashboard;
