import axios from "./axios";

async function axiosRequestPostNodeJS(service, body = {}, params = {}) {
  try {
    const baseURL = `/${service}`;

    const targetURL = baseURL;

    let headerConfig = {
      headers: {
        URL: baseURL,
        "Content-Type": "application/json",
      },
      params,
      cancelToken: axios.axiosCancel().getCancelToken(),
    };
    const response = await axios.post(targetURL, body, headerConfig);

    return response;
  } catch (e) {
    console.error("errror ----- httpsevice file--", e);
    throw e;
  }
}

async function axiosRequestDeleteNodeJS(service, params) {
  try {
    const baseURL = `/${service}`;

    const targetURL = baseURL;

    const response = await axios.delete(targetURL, {
      headers: { URL: baseURL },
      // timeout: 3000,
      data: { ...params },
      cancelToken: axios.axiosCancel().getCancelToken(),
    });

    return response;
  } catch (e) {
    console.error("errror ----- httpsevice file--", e);
    throw e;
  }
}

async function axiosRequestGetNodeJS(service, params = {}) {
  try {
    const baseURL = `/${service}`;

    const targetURL = baseURL;

    const response = await axios.get(targetURL, {
      headers: { URL: baseURL },
      // timeout: 3000,
      params,
      cancelToken: axios.axiosCancel().getCancelToken(),
    });

    return response;
  } catch (error) {
    console.error("errror ----- httpsevice file--", error);
    throw error;
  }
}

export const axiosProviderNode = {
  get: axiosRequestGetNodeJS,
  post: axiosRequestPostNodeJS,
  delete: axiosRequestDeleteNodeJS,
};
