import "./index.scss";

import React from "react";

import Listitem from "./listitem";

import Icon from "../../../../designer/components/icon";

import { collectionlist } from "../mock";

function CollectionsBody(props) {
  const { collectionData = collectionlist } = props;

  return (
    <div className="container-fluid collectionBody pt-4">
      {collectionData?.map((collection, index) => (
        <div className="row" key={index}>
          <div className="col-1 col-sm-1 col-md-1 text-right p-1 pt-3 side-icon">
            <Icon color="red" icon={collection.icon} />
          </div>
          <div className="col-10 col-sm-10 col-md-10 p-1">
            <Listitem collection={collection} />
          </div>

          <div className="col-1 col-sm-1 col-md-1 p-1"></div>
        </div>
      ))}
    </div>
  );
}

export default CollectionsBody;
