import { getMaximoConfig } from "../../../../services";

function __getScripts(setData = () => {}, handleError = () => {}) {
  getMaximoConfig()
    .then((response) => {
      if (response?.content?.status === "failure") {
        handleError(response?.content?.message);
      } else {
        setData(response.content?.dashboardUser?.scriptsStatus);
      }
    })
    .catch((error) => {
      handleError(error.message);
    });
}

export default __getScripts;
