import "./index.scss";

import React from "react";

function Accordionprogress(props) {
  const { data = {}, colorLevel = 1 } = props;

  return (
    <>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2
            className={`accordion-header pt-2 h2progress${colorLevel}`}
            id={`panelsStayOpen-heading-${data.name}`}
          >
            <div className="progress ">
              {data.start.replace(/[^a-z ]/g, "") === "s" ? (
                <div
                  className="progress-bar"
                  style={{
                    width: parseFloat(data.start) * 10 + "%",
                    backgroundColor: "white",
                  }}
                ></div>
              ) : (
                <div
                  className="progress-bar"
                  style={{
                    width: parseFloat(data.start) / 100 + "%",
                    backgroundColor: "white",
                  }}
                ></div>
              )}

              {data.total.replace(/[^a-z ]/g, "") === "s" ? (
                <div
                  className={`progress-bar progress-bar-stripped colorLevel${colorLevel}`}
                  style={{
                    width: parseFloat(data.total) * 10 + "%",
                  }}
                ></div>
              ) : (
                <div
                  className={`progress-bar progress-bar-stripped colorLevel${colorLevel}`}
                  style={{
                    width: parseFloat(data.total) / 100 + "%",
                  }}
                ></div>
              )}

              <div
                className="progress-bar progress-bar-stripped progress-bar-text"
                style={{ width: "80%", backgroundColor: "white" }}
              >
                {data.total}
              </div>
            </div>
          </h2>
          <div
            id={`panelsStayOpen-collapse-${data.name}`}
            className="accordion-collapse collapse"
            aria-labelledby={`panelsStayOpen-collapse-${data.name}`}
          >
            <div className="accordion-body">
              {data.subapi?.length > 0 && (
                <div className="accordion-body">
                  <ul className="timelineprogress">
                    {data.subapi.map((sub, index) => (
                      <li className="liprogress" key={index}>
                        <Accordionprogress
                          data={sub}
                          colorLevel={colorLevel + 1}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Accordionprogress;
