import "./InstallerItem.scss";

import React, { useState } from "react";

import AlertDialog from "../../alert-dialog/AlertDialog";

import InstallError from "../../../../../../assets/lottie-files/error.json";
import Warning from "../../../../../../assets/lottie-files/warning.json";
import Valid from "../../../../../../assets/lottie-files/valid.json";

import Lottie from "react-lottie";
import Loading from "../../../../../../assets/lottie-files/loading-script.json";

function InstallerItem(props) {
  const { script = {} } = props;

  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const ValidOptions = {
    loop: false,
    autoplay: true,
    animationData: Valid,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const ErrorOptions = {
    loop: false,
    autoplay: true,
    animationData: InstallError,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const WarningOptions = {
    loop: false,
    autoplay: true,
    animationData: Warning,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [showDialog, setShowDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = (type) => {
    setShowDialog(true);
    setDialogType(type);
  };

  return (
    <div
      id="InstallerItem"
      className="InstallerItem"
      data-testid="InstallerItem"
    >
      <span className="file-name" data-testid="file-name">
        {script?.content?.name?.slice(0, -3)
          ? script?.content?.name?.slice(0, -3)
          : script?.name?.slice(0, -3)}
      </span>
      {script?.status ? (
        <span className="file-status">
          {script?.status?.toString()?.toUpperCase() === "FAILURE" && (
            <button
              className="file-error"
              onClick={() => {
                openDialog("error");
              }}
            >
              <Lottie
                isClickToPauseDisabled={true}
                options={ErrorOptions}
                height={27}
                width={27}
                style={{
                  cursor: "auto",
                }}
              />
            </button>
          )}
          {script?.status?.toString()?.toUpperCase() === "WARNING" && (
            <button
              className="file-error"
              onClick={() => {
                openDialog("warning");
              }}
            >
              <Lottie
                isClickToPauseDisabled={true}
                options={WarningOptions}
                height={27}
                width={27}
                style={{
                  cursor: "auto",
                }}
              />
            </button>
          )}
          {script?.status?.toString()?.toUpperCase() === "SUCCESS" && (
            <button className="file-success">
              <Lottie
                isClickToPauseDisabled={true}
                options={ValidOptions}
                height={30}
                width={30}
                style={{
                  cursor: "auto",
                }}
              />
            </button>
          )}
        </span>
      ) : (
        <div className="file-status">
          <Lottie
            isClickToPauseDisabled={true}
            options={LoadingOptions}
            height={30}
            width={30}
            style={{
              cursor: "auto",
            }}
          />
        </div>
      )}

      <AlertDialog
        dialogHeader={script?.message ? script?.message : ""}
        show={showDialog}
        CloseDialog={closeDialog}
        type="alert"
      >
        <div className="close-alert-error">
          <button
            className={`close-btn ${dialogType}`}
            onClick={(e) => {
              e.stopPropagation();
              closeDialog();
            }}
            data-testid="No"
          >
            <span className="dialog-btn-text">OK</span>
          </button>
        </div>
      </AlertDialog>
    </div>
  );
}

export default InstallerItem;
