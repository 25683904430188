import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../http/httpService";
import handleClickedItem from "./helpers/handleClickedItem";

async function deleteVersion(store, filename, addToast, version) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DELETE_FILE;

    const transactionPayload = {
      filename: filename,
    };

    const res = await serviceRequest.delete(
      transactionNodeService,
      transactionPayload
    );

    if (res?.data?.success) {
      store.dispatch({
        type: "revision/deleteData",
        paylaod: {
          filename: filename,
        },
      });

      if (version) {
        let list = store.getState()?.entities?.revision?.list;
        store.dispatch({
          type: "revision/setVersion",
          paylaod: {
            version: list.length > 0 ? version : 0,
          },
        });

        if (list.length > 0) {
          await handleClickedItem(
            addToast,
            store,
            {},
            list[0]?.filename,
            false,
            true
          );
        }
      }

      addToast(res.data.message, {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } else {
      addToast(res.data.message, {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  } catch (error) {
    addToast("Server is unreachable!", {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
  }
}

export default deleteVersion;
