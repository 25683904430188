import "./index.scss";

import { Chart } from "react-google-charts";

import { ChartData as mockData } from "./mock";

function ChartCard(props) {
  const { data = mockData } = props;
  const options = {
    chart: {
      title: data?.title || "",
      subtitle: data?.subtitle || "",
      is3D: true,
      legend: { position: "bottom" },
    },
  };
  return (
    <>
      <Chart
        chartType="Bar"
        width="100%"
        height="400px"
        data={data?.data}
        options={options}
      />
    </>
  );
}

export default ChartCard;
