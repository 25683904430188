import "./index.scss";
import React from "react";
import DashboardApp from "../dashboard/Dashboard";
import Sidebar from "./components/monitor-sidebar";

function Monitor(props) {
  return (
    <DashboardApp>
      <Sidebar>
        <div className="monitorBody">
          <div className="w-100 content"></div>
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export default Monitor;
