import React, { useRef, useState } from "react";
import mergeArray from "./services/mergeArrays";
import uploadIconToServer from "./services/uploadIconToServer";
import { useToasts } from "react-toast-notifications";

const iconMaxSize = 1024 * 1024; // 1 MB

function ImageUpload({ setIconsListing }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const fileInputRef = useRef(null);
  const { addToast } = useToasts();

  const submitForm = (e) => {
    e.preventDefault();
    if (selectedFiles?.length === 0) {
      addToast("No PNG files selected.", {
        appearance: "info",
        autoDismiss: true,
      });
      return;
    }

    const formdata = new FormData();
    for (let index = 0; index < selectedFiles?.length; index++) {
      const file = selectedFiles[index];

      if (file?.size > iconMaxSize) {
        addToast("Icons size is too wide >" + iconMaxSize, {
          appearance: "warning",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
        return;
      }
      formdata?.append("files", file);
    }

    uploadIconToServer(formdata, addToast).then((uploadedFiles) => {
      setIconsListing((oldIconsList) =>
        mergeArray(oldIconsList, uploadedFiles, "iconName")
      );
      setPreviewImage([]); // Clear preview after submit
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset the file input
      }
    });
  };

  const handleFileInput = (e) => {
    const files = Array.from(e?.target?.files);
    const pngFiles = files?.filter((file) => file?.type === "image/png");

    if (pngFiles.length < files?.length) {
      addToast("Only PNG files are allowed.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    setSelectedFiles(pngFiles);
    const filesArray = pngFiles?.map((file) => URL.createObjectURL(file));
    setPreviewImage(filesArray);

    // Cleanup to avoid memory leaks
    files?.forEach((file) => URL.revokeObjectURL(file));
  };

  const renderPhotos = (source) => {
    return source?.map((photo, index) => (
      <img
        style={{ height: 30, width: 30, objectFit: "contain" }}
        src={photo}
        alt=""
        key={index}
      />
    ));
  };

  return (
    <div>
      <form onSubmit={submitForm}>
        <div>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileInput}
            multiple
            accept="image/png"
            // style={{ display: "none" }}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      <p style={{ color: "red" }}>Preview Image:</p>
      {previewImage?.length > 0 ? (
        <div className="result">{renderPhotos(previewImage)}</div>
      ) : (
        <p>No Preview</p>
      )}
    </div>
  );
}

export default ImageUpload;
