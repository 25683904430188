const explorer = {
  initialState: {
    skeleton: {},
    openDefault: {},
    readOnly: true,
    draftDisplay: false,
    draftSkeleton: {},
    maxOrder: -1,
  },
  reducers: {
    incrementOrder: (dataSet, action) => {
      const { order = 0 } = action.paylaod;
      dataSet["maxOrder"] = order;
    },
    dataAdded: (dataSet, action) => {
      const { skeleton, readOnly } = action.paylaod;
      dataSet["skeleton"] = skeleton;
      dataSet["readOnly"] = readOnly;
    },
    updateState: (dataSet, action) => {
      const { draftSkeleton, skeleton, draftDisplay, readOnly } =
        action?.paylaod;
      dataSet["draftSkeleton"] = draftSkeleton;
      dataSet["readOnly"] = readOnly;
      dataSet["skeleton"] = skeleton;
      dataSet["draftDisplay"] = draftDisplay;
    },
    updateData: (dataSet, action) => {
      const { skeleton, readOnly } = action.paylaod;
      dataSet["skeleton"] = skeleton;
      dataSet["readOnly"] = readOnly;
    },
    displayDraft: (dataSet, action) => {
      const { draftDisplay } = action?.paylaod;
      dataSet["draftDisplay"] = draftDisplay;
    },
    addDraftSkeleton: (dataSet, action) => {
      const { skeleton } = action?.paylaod;
      dataSet["draftSkeleton"] = skeleton;
    },
    reset: (dataSet) => {
      // if (!dataSet["draftDisplay"]) {
      return (dataSet = {
        skeleton: {},
        openDefault: {},
        readOnly: true,
        draftDisplay: false,
        draftSkeleton: {},
      });
      // }
    },
  },
};

export default explorer;
