import "./index.scss";

import React from "react";

import HardWare from "./hard-ware";
import UsersCard from "./users-card";
import InfoCard from "./info-card";
import ChartCard from "./charts";
import { getMockData } from "./mock";

import MailForm from "./mail-form/mail-form";
import CustomIcons from "./customIcons";
import Sidebar from "../monitor-sidebar";
import DashboardApp from "../../../dashboard/Dashboard";
import { useMonitorSocket } from "../../../customHooks.js/useMonitorSocket";

function Dashboard() {
  // { data }
  const data = useMonitorSocket();

  const mock = getMockData();
  const {
    users = mock?.users,
    hardware = mock?.hardware,
    infos = mock?.infos,
    charts = mock?.charts,
  } = data?.dashboard || {};

  return (
    <DashboardApp>
      <Sidebar>
        <div className="monitor-dashboard">
          <div className="d-flex p-2">
            {users?.map((user, index) => (
              <UsersCard
                key={index}
                value={user.number}
                description={user.type}
              />
            ))}
          </div>

          <div className="d-flex p-2">
            {hardware?.map((item, index) => (
              <HardWare
                key={index}
                value={item.current}
                maxvalue={item.max}
                name={item.name}
                icon="monitoring"
                progressColor={item.color}
              />
            ))}
          </div>
          <div>
            <CustomIcons />
          </div>
          <div>
            <MailForm />
          </div>
          <div className="dashboard-infos">
            {infos?.map((info, index) => (
              <InfoCard key={index} data={info} icon="monitoring" />
            ))}
          </div>
          <div className="d-flex p-2 row">
            {charts?.map((chart, index) => (
              <div key={index} className="col-5 col-sm-5 col-md-5 ">
                <ChartCard data={chart} />
              </div>
            ))}
          </div>
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export default Dashboard;
