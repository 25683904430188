function getFailedListScripts(responses = []) {
  if (!Array.isArray(responses)) {
    return [];
  }
  const filtredList = responses?.filter((script) => {
    if (
      script?.success === false ||
      (script?.success === true &&
        script?.content?.status.toUpperCase() === "FAILURE")
    ) {
      return script;
    }
  });

  return filtredList;
}

export default getFailedListScripts;
