import React from "react";

import ListItem from "@mui/material/ListItem";

function Listitem(props) {
  const { bug } = props;
  return (
    <ListItem className="listitems" alignItems="flex-start">
      <div className="MuiListItemText-root MuiListItemText-multiline css-konndc-MuiListItemText-root">
        <span className="text-date px-1">{bug.date}</span>
        {"  "}
        <span className="textInfo px-3">Info</span>
        <p className="mt-2 mb-1 w-75">{bug.description}</p>
        <span className="service-text">
          <b>service: {bug.service} </b>
        </span>
      </div>
    </ListItem>
  );
}

export default Listitem;
