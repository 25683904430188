import "./index.scss";

import React from "react";

import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";

import { MenuItem } from "react-pro-sidebar";

import Icon from "../../../designer/components/icon";

function SideNavItem(props) {
  const { navItemsList } = props;

  let installStatus = useSelector(
    (state) => state?.entities?.dashboard?.completedInstallation
  );

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  return (
    <>
      {navItemsList?.map((navItems, index) => (
        <MenuItem
          className={
            (!readOnly &&
              (navItems?.name === "Monitor" ||
                navItems?.name === "Installer")) ||
            (!installStatus && navItems?.name === "Designer")
              ? "nav-link-disabled"
              : ""
          }
          key={index}
          icon={
            <Icon
              size={30}
              color={
                (!readOnly &&
                  (navItems?.name === "Monitor" ||
                    navItems?.name === "Installer")) ||
                (!installStatus && navItems?.name === "Designer")
                  ? "gray"
                  : "#EECD49"
              }
              icon={navItems?.icon}
            />
          }
        >
          {navItems?.name}
          {navItems?.name !== "Designer" ? (
            <NavLink to={`/${navItems?.name}`} />
          ) : (
            installStatus && <NavLink to={`/${navItems?.name}`} />
          )}
        </MenuItem>
      ))}
      {!readOnly && (
        <p style={{ color: "red", marginLeft: "10px", alignItems: "center" }}>
          Publish OR Cancel your draft before navigating !
        </p>
      )}
    </>
  );
}

export default SideNavItem;
