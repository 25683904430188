import "./InstallScreen.scss";

import React, { useCallback, useEffect, useState } from "react";

import AlertDialog from "../alert-dialog/AlertDialog";
import ForwardButton from "../forward-button/ForwardButton";
import ItemsWrapper from "../items-install/ItemsWrapper";
import ProgressBar from "../progress-bar/ProgressBar";

import Loading from "../../../../../assets/lottie-files/loader.json";
import Lottie from "react-lottie";

import __getData from "./services/__getData";
import oneScriptItallation from "../../../services/installScripts/modules/oneScriptItallation";
import getScripts from "../../../services/installScripts/modules/getScripts";

function InstallScreen({ handleNext }) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [scriptsList, setScriptsList] = useState([]);
  const [scriptsNumber, setScriptsNumber] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    openDialog();
  }, []);

  //InstallAll
  const __installAll = useCallback(async () => {
    try {
      let files = await getScripts();
      let remoteScripts =
        files?.content?.status?.toUpperCase() === "SUCCESS"
          ? files?.content?.result
          : [];

      const scriptsArray = files?.content?.result || [];
      const IMXAPPS__CONFIGLOGGER__file =
        scriptsArray.filter((file) =>
          /(IMXAPPS__CONFIGLOGGER)\.py$/i.test(file)
        )?.[0] || "";
      const IMXAPPS__CONFIGIMXLOGGER__file =
        scriptsArray.filter((file) =>
          /(IMXAPPS__CONFIGIMXLOGGER)\.py$/i.test(file)
        )?.[0] || "";

      const filtredScript = remoteScripts.filter(
        (script) =>
          script !== IMXAPPS__CONFIGLOGGER__file &&
          script !== IMXAPPS__CONFIGIMXLOGGER__file
      );
      let allInstalledScript = [];
      const result = await Promise.allSettled(
        [IMXAPPS__CONFIGLOGGER__file, IMXAPPS__CONFIGIMXLOGGER__file].map(
          async (script) => {
            const scriptResult = await oneScriptItallation(script);
            allInstalledScript = [...allInstalledScript, scriptResult];
            const percentage = Math.round(
              (allInstalledScript.filter((item) => item.status !== "failure")
                .length /
                remoteScripts.length) *
                100
            );
            setProgress(percentage);
            setScriptsList(allInstalledScript);
            return true;
          }
        )
      );

      if (result) {
        await Promise.allSettled(
          filtredScript.map(async (script) => {
            const scriptResult = await oneScriptItallation(script);
            allInstalledScript = [...allInstalledScript, scriptResult];
            const percentage = Math.round(
              (allInstalledScript.filter((item) => item.status !== "failure")
                .length /
                remoteScripts.length) *
                100
            );
            setProgress(percentage);
            setScriptsList(allInstalledScript);
          })
        );
      }

      setIsCompleted(true);
    } catch (error) {
      handleError(error.message);
    }
  }, [handleError]);

  const prepareData = useCallback(() => {
    __getData(setScriptsNumber, handleError);
  }, [handleError]);

  useEffect(() => {
    const ac = new AbortController();
    if (!scriptsNumber) {
      prepareData();
    } else {
      __installAll();
    }
    return () => ac.abort();
  }, [scriptsNumber, __installAll, prepareData]);

  return (
    <div
      id="InstallScreen"
      className="InstallScreen"
      data-testid="InstallScreen"
    >
      <div className="install-progress">
        <div className="progress-header">
          <p className="progress-title">Installation </p>
        </div>
        <ProgressBar
          textColor="#6f53a3"
          installStatus=""
          bgcolor="#6f53a3"
          itemName=""
          completed={progress}
        />
      </div>
      <div className="scripts-list">
        {scriptsList ? (
          <ItemsWrapper filesList={scriptsList} />
        ) : (
          <div>
            <Lottie
              isClickToPauseDisabled={true}
              options={LoadingOptions}
              height={60}
              width={60}
              style={{
                cursor: "auto",
              }}
            />
          </div>
        )}
      </div>
      <div className="control-btns">
        <ForwardButton
          icon="chevron-right"
          text="Next"
          handleAction={handleNext}
          isActive={{ toLoad: false, disable: !isCompleted }}
        />
      </div>
      <AlertDialog
        dialogHeader={errorMessage}
        show={showDialog}
        CloseDialog={closeDialog}
        type="alert"
      >
        <div className="close-alert-error">
          <button
            className="close-btn"
            onClick={(e) => {
              e.stopPropagation();
              prepareData();
              closeDialog();
            }}
            data-testid="No"
          >
            <span className="dialog-btn-text">Retry</span>
          </button>
        </div>
      </AlertDialog>
    </div>
  );
}

export default InstallScreen;
