import "./index.scss";

import React from "react";

import { useSelector } from "react-redux";

import SplitPane from "react-split-pane";

import Explorer from "./explorer-nav";
import Preview from "./preview";
import EditPane from "./edit-pane";

function DesignerBody() {
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  return (
    <div id="DesignerBody" className="DesignerBody" data-testid="DesignerBody">
      <SplitPane split="vertical" minSize="20%" defaultSize="20%">
        <Explorer data={skeletonState} />
        <SplitPane split="vertical" minSize="20%" defaultSize="70%">
          <EditPane />
          <Preview />
        </SplitPane>
      </SplitPane>
    </div>
  );
}

export default DesignerBody;
