import { getMaximoConfig } from "../../../services";

function __getScripts(setMaximoConfig = () => {}, checkMount) {
  getMaximoConfig()
    .then((response) => {
      if (checkMount) {
        setMaximoConfig({
          syncBackendStatus: response.content?.dashboardUser?.syncBackendStatus,
          maximoUrl: response.content?.dashboardUser?.maximoUrl,
          scripts: response.content?.dashboardUser?.scriptsStatus
            ?.sort((a, b) => Number(a.status) - Number(b.status))
            .sort((a, b) => Number(a.loggerStatus) - Number(b.loggerStatus)),
          maxappsHost: response.content?.dashboardUser?.maxappsHost,
        });
      }
    })
    .catch((error) => {
      console.error("Error=====>", error);
    });
}

export default __getScripts;
