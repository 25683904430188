import { axiosProviderNode } from "../../../../../http/httpService";
import { services } from "../configVariables";

async function addStatsThresholdInfos(data = {}) {
  try {
    const transactionNodeService =
      services.NODE_ENDPOINT_DASHBOARD_ADDSTATSTHRESHOLDINFOS;

    const body = {
      thresholdNotificationEmails: data?.thresholdNotificationEmails,
      statsThreshold: {
        cpuThresholdValue: data?.cpuThresholdValue
          ? data.cpuThresholdValue
          : 100,
        ramThresholdValue: data?.ramThresholdValue
          ? data.ramThresholdValue
          : 100,
        hddThresholdValue: data?.hddThresholdValue
          ? data.hddThresholdValue
          : 100,
      },
    };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error("dashboard addStatsThresholdInfos error", error);
    if (error?.response?.status === 500) {
      return {
        status: false,
        message: "Cannot connect to Maxapps sync Server",
      };
    }
    throw error;
  }
}

export default addStatsThresholdInfos;
