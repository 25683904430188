import "./NotFound.scss";

import React from "react";

function NotFound() {
  return (
    <div id="NotFound" className="NotFound">
      <div className="error-page-code">
        <span>404</span>
      </div>
      <div className="error-page-text">
        <span>The requested page is not found!</span>
      </div>
    </div>
  );
}

export default NotFound;
