import "./BackButton.scss";

import React from "react";

import Icon from "../../../../designer/components/icon";

function BackButton(props) {
  const { icon = null, text, handleAction, isActive, type = "" } = props;

  return (
    <button
      type={type}
      id="BackButton"
      data-testid="BackButton"
      className={isActive ? "BackButton" : "Backdisabled"}
      onClick={handleAction}
      disabled={!isActive}
    >
      {icon && (
        <Icon icon={icon} size={20} color={isActive ? "#000" : "#fff"} />
      )}
      <span className="backbutton-text" data-testid="back-button-text">
        {text}
      </span>
    </button>
  );
}

export default BackButton;
