import "./PreRequest.scss";

import React, { useState } from "react";

import AlertDialog from "../alert-dialog/AlertDialog";
import BackButton from "../back-button/BackButton";
import ForwardButton from "../forward-button/ForwardButton";

import { verifyAutoscripts } from "../../../services/index";

function PreRequest({ title, handleNext, handlePrevious, actionName }) {
  const [isVerifying, setIsVerifying] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const redirectTo = () => {
    setIsVerifying(true);
    verifyAutoscripts()
      .then((response) => {
        if (response?.content?.status === "failure") {
          setIsVerifying(false);
          setErrorMessage(response?.content?.message);
          openDialog();
        } else {
          if (response.content?.result?.exist) {
            handleNext();
          } else {
            handleNext(4);
          }
        }
      })
      .catch((error) => {
        setIsVerifying(false);
        setErrorMessage(error.message);
        openDialog();
      });
  };

  return (
    <div id="PreRequest" className="PreRequest" data-testid="PreRequest">
      <div className="pre-install-header">
        <div className="prequest-title">
          <p className="title">{title}</p>
        </div>
      </div>
      <div className="pre-install-footer">
        <BackButton
          type="submit"
          text="Cancel"
          isActive={!isVerifying}
          icon="chevron-left"
          handleAction={() => handlePrevious()}
        />
        <ForwardButton
          type="submit"
          text={actionName}
          isActive={{ toLoad: true, disable: isVerifying }}
          icon="import"
          handleAction={redirectTo}
        />
      </div>

      <AlertDialog
        dialogHeader={errorMessage}
        show={showDialog}
        CloseDialog={closeDialog}
        type="alert"
      >
        <div className="close-alert-error">
          <button
            className="close-btn"
            onClick={(e) => {
              e.stopPropagation();
              closeDialog();
            }}
            data-testid="No"
          >
            <span className="dialog-btn-text">OK</span>
          </button>
        </div>
      </AlertDialog>
    </div>
  );
}

export default PreRequest;
