import getFileContent from "../getFileContent";

async function handleEditContentVersion(
  addToast,
  store,
  filenametoGetContent,
  order
) {
  const res = await getFileContent(
    store,
    filenametoGetContent,
    false,
    addToast
  );

  if (res?.data?.success) {
    const skeleton = store.getState().entities.explorer.skeleton;

    store.dispatch({
      type: "explorer/incrementOrder",
      paylaod: { order },
    });

    store.dispatch({
      type: "explorer/displayDraft",
      paylaod: { draftDisplay: true },
    });

    store.dispatch({
      type: "revision/setSelectedItem",
      paylaod: {},
    });

    store.dispatch({
      type: "explorer/addDraftSkeleton",
      paylaod: {
        skeleton,
      },
    });
  }
}

export default handleEditContentVersion;
