import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "../../configVariables";

async function oneScriptItallation(script) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_ONE_SCRIPT_INSTALL;

    const body = { script };

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    return {
      status: "failure",
      message: error?.message ? error?.message : error,
      content: {
        loggerStatus: false,
        name: script,
      },
    };
  }
}
export default oneScriptItallation;
