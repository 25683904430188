import "./LoadingScreen.scss";

import React from "react";

import Lottie from "react-lottie";
import Loader from "../../../../../assets/lottie-files/loader.json";

function LoadingScreen() {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      id="LoadingScreen"
      className="LoadingScreen"
      data-testid="LoadingScreen"
    >
      <Lottie
        isClickToPauseDisabled={true}
        options={LoadingOptions}
        height={60}
        width={60}
        style={{
          cursor: "auto",
        }}
      />
    </div>
  );
}

export default LoadingScreen;
