function fineUrl(url) {
  const trimmedUrl = url.trim();

  if (trimmedUrl?.slice(-1) !== "/") return url;
  else {
    let newUrl = trimmedUrl?.slice(0, trimmedUrl.length - 1);
    return fineUrl(newUrl);
  }
}

export default fineUrl;
