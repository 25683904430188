import "./ForwardButton.scss";

import React from "react";

import Lottie from "react-lottie";
import Loading from "../../../../../assets/lottie-files/loader.json";

import Icon from "../../../../designer/components/icon";

function ForwardButton(props) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const {
    icon = null,
    text,
    handleAction = () => {},
    isActive = {},
    type = "",
    step = 1,
  } = props;

  return (
    <button
      type={type}
      id="ForwardButton"
      className={
        isActive.disable || isActive.disabeIncorrectInputs
          ? "ForwardDisabled"
          : "ForwardButton"
      }
      onClick={() => handleAction(step)}
      disabled={isActive.disable || isActive.disabeIncorrectInputs}
      data-testid="ForwardButton"
    >
      <span className="forwardbutton-text" data-testid="forward-button-text">
        {text}
      </span>
      {isActive.toLoad && isActive.disable ? (
        <Lottie
          isClickToPauseDisabled={true}
          options={LoadingOptions}
          height={35}
          width={35}
          style={{
            cursor: "auto",
          }}
        />
      ) : (
        icon && <Icon icon={icon} size={25} color="#fff" />
      )}
    </button>
  );
}

export default ForwardButton;
