import "./MailForm.scss";

import React, { useEffect, useState } from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import addStatsThresholdInfos from "./services/addStatsThresholdInfos";
import __getStatsThresholdInfos from "./services/getStatsThresholdInfos";

const statsThresholdSchema = Yup.object().shape({
  thresholdNotificationEmails: Yup.string().required("Please enter the Email"),
  cpuThresholdValue: Yup.number()
    .required("Please enter the CPU value in % ")
    .min(1)
    .max(100),
  ramThresholdValue: Yup.number()
    .required("Please enter the RAM value in %")
    .min(1)
    .max(100),
  hddThresholdValue: Yup.number()
    .required("Please enter the HDD value in %")
    .min(1)
    .max(100),
});

function MailForm() {
  const [loginMessage, setLoginMessage] = useState("");
  const [mailsInfo, setMailsInfo] = useState();

  useEffect(() => {
    __getStatsThresholdInfos(setMailsInfo);
    return () => {};
  }, []);

  return !mailsInfo ? (
    <>Loading ...</>
  ) : (
    <div id="MailForm" className="MailForm" data-testid="MailForm">
      <Formik
        initialValues={{
          thresholdNotificationEmails: mailsInfo?.mailsSender || "",
          cpuThresholdValue:
            mailsInfo?.statsThreshold?.cpuThresholdValue || 100,
          ramThresholdValue:
            mailsInfo?.statsThreshold?.ramThresholdValue || 100,
          hddThresholdValue:
            mailsInfo?.statsThreshold?.hddThresholdValue || 100,
        }}
        validationSchema={statsThresholdSchema}
        onSubmit={(values) => {
          const {
            thresholdNotificationEmails,
            cpuThresholdValue,
            ramThresholdValue,
            hddThresholdValue,
          } = values;
          addStatsThresholdInfos({
            thresholdNotificationEmails,
            cpuThresholdValue,
            ramThresholdValue,
            hddThresholdValue,
          })
            .then((res) => setLoginMessage("Submit successfull !"))
            .catch((err) => setLoginMessage(err.message.toString()));

          setTimeout(() => {
            setLoginMessage("");
          }, 1000);
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <div className="mail-form-container">
            <div className="col-lg">
              <p className="form-title">Set Stats Threshold Information</p>
              <br />
            </div>
            <Form>
              <div className="form-group">
                <label
                  className={
                    values.thresholdNotificationEmails
                      ? "filled  input-label"
                      : " input-label"
                  }
                  htmlFor="url"
                >
                  Emails
                </label>

                <Field
                  data-testid="thresholdNotificationEmails"
                  type="text"
                  name="thresholdNotificationEmails"
                  placeholder=""
                  autoComplete="off"
                  className={`mt-2 form-control
              ${
                touched.thresholdNotificationEmails &&
                errors.thresholdNotificationEmails
                  ? "is-invalid"
                  : ""
              }`}
                />
                <ErrorMessage
                  component="div"
                  name="thresholdNotificationEmails"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.cpuThresholdValue
                      ? "filled  input-label"
                      : " input-label"
                  }
                  htmlFor="url"
                >
                  CPU Threshold
                </label>

                <Field
                  data-testid="cpuThresholdValue"
                  type="text"
                  name="cpuThresholdValue"
                  placeholder=""
                  autoComplete="off"
                  className={`mt-2 form-control
              ${
                touched.cpuThresholdValue && errors.cpuThresholdValue
                  ? "is-invalid"
                  : ""
              }`}
                />
                <ErrorMessage
                  component="div"
                  name="cpuThresholdValue"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.ramThresholdValue
                      ? "filled  input-label"
                      : " input-label"
                  }
                  htmlFor="url"
                >
                  RAM Threshold
                </label>
                <Field
                  data-testid="ramThresholdValue"
                  type="text"
                  name="ramThresholdValue"
                  placeholder=""
                  autoComplete="off"
                  className={`mt-2 form-control
              ${
                touched.ramThresholdValue && errors.ramThresholdValue
                  ? "is-invalid"
                  : ""
              }`}
                />
                <ErrorMessage
                  component="div"
                  name="ramThresholdValue"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group">
                <label
                  className={
                    values.hddThresholdValue
                      ? "filled  input-label"
                      : " input-label"
                  }
                  htmlFor="url"
                >
                  HDD Threshold
                </label>
                <Field
                  data-testid="hddThresholdValue"
                  type="text"
                  name="hddThresholdValue"
                  placeholder=""
                  autoComplete="off"
                  className={`mt-2 form-control
              ${
                touched.hddThresholdValue && errors.hddThresholdValue
                  ? "is-invalid"
                  : ""
              }`}
                />
                <ErrorMessage
                  component="div"
                  name="hddThresholdValue"
                  className="invalid-feedback"
                />
              </div>
              <div className="response-error">
                <span className="login-error">{loginMessage}</span>
              </div>
              <div className="submit" data-testid="submit">
                <button
                  type={"submit"}
                  id="ConnectButton"
                  data-testid="ConnectButton"
                  className="ForwardButton"
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default MailForm;
