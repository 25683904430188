const ChartData = {
  title: "nice couple charts",
  subtitle: "this is random for test",
  data: [
    ["x ", "y", "z"],
    ["2014", 400, 200],
    ["2015", 460, 250],
    ["2016", 1120, 300],
    ["2017", 540, 350],
    ["2018", 1540, 50],
    ["2019", 1540, 500],
    ["2020", 540, 350],
  ],
};

export { ChartData };
