export const getMockData = () => ({
  hardware: [
    { name: "CPU", max: 100, current: "N/A" },
    { name: "RAM", max: "N/A", current: "N/A" },
    { name: "HDD", max: 100, current: "N/A" },
  ],
  infos: [
    { type: "Warnings", number: "N/A" },
    { type: "Errors", number: "N/A" },
  ],
  users: [
    { type: "Connected users", number: 0 },
    { type: "Connected GiS users", number: 0 },
  ],
});
