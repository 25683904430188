const editor = {
  initialState: { dataToSet: {}, parentToEdit: [], component: "" },
  reducers: {
    dataAdded: (dataSet, action) => {
      const { data, parent, component } = action.paylaod;
      Array.isArray(data)
        ? (dataSet["dataToSet"] = {})
        : (dataSet["dataToSet"] = { ...data });
      dataSet["parentToEdit"] = parent;
      dataSet["component"] = component;
    },
    updateData: (dataSet, action) => {
      const { data } = action.paylaod;
      dataSet["dataToSet"] = { ...data };
    },
    reset: (dataSet) => {
      return (dataSet = { dataToSet: {}, parentToEdit: [], component: "" });
    },
  },
};

export default editor;
