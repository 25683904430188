import React from "react";

import ReinstallPrerequest from "../global-components/reinstall-prerequest/ReinstallPrerequest";

function UninstallPrerequest({ handleNext, handlePrevious }) {
  return (
    <div
      id="UninstallPrerequest"
      className="UninstallPrerequest"
      data-testid="UninstallPrerequest"
    >
      <ReinstallPrerequest
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
    </div>
  );
}

export default UninstallPrerequest;
