import "./index.scss";

import React, { useState } from "react";

import Accordioncontent from "./listitems/Accordioncontent";
import Accordionprogress from "./listitems/Accordionprogress";

function Server(props) {
  const { serverData = {} } = props;
  const [descriptioncontent, setDescriptioncontent] = useState({});

  return (
    <div className="row">
      <div className="ServerBody col-9 col-sm-9 col-md-9">
        {serverData.map((data, index) => (
          <div className="row ServerListItems" key={index}>
            <div className="col-1 col-sm-1 col-md-1 text-right p-3 pt-3 side-icon"></div>
            <div className="col-3 col-sm-3 col-md-3 p-1 m-1  rounded ">
              <Accordioncontent
                setDescriptioncontent={setDescriptioncontent}
                data={data}
              />
            </div>
            <div className="col-8 col-sm-8 col-md-8 p-1 m-1 bg-white rounded">
              <div>
                <Accordionprogress data={data} />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="col-3 col-sm-3 col-md-3  bg-white rounded desc">
        <h5>{descriptioncontent?.name}</h5>
        <span>{descriptioncontent?.description}</span>
      </div>
    </div>
  );
}

export default Server;
