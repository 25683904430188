import React, { useEffect } from "react";

import { useSelector, useStore } from "react-redux";

import StepperSingleton from "../singleton/stepper";
import Welcome from "../components/welcome/Welcome";
import DisconnectScreen from "./disconnect-screen/DisconnectScreen";
import UninstallPrerequest from "../components/reinstall-prerequest/ReinstallPrerequest";
import MaximoLogin from "../components/maximo-login/MaximoLogin";
import InstallationVerification from "../components/installation-verification/InstallationVerification";
import LoadingScreen from "../components/global-components/loading-screen/LoadingScreen";
import MaximoSetup from "./maximo-setup/MaximoSetup";

import AutoscriptsPrequest from "./autoscript-prerequest/AutoscriptsPrequest";
import AutoscriptsInstall from "./autoscript-install/AutoscriptsInstall";
import AutoscriptPostinstall from "./autoscript-postinstall/AutoscriptPostinstall";
import getServerConfig from "../services/getServerConfig";

const Components = [
  Welcome,
  MaximoLogin,
  AutoscriptsPrequest,
  UninstallPrerequest,
  AutoscriptsInstall,
  AutoscriptPostinstall,
  MaximoSetup,
  InstallationVerification,
  DisconnectScreen,
];

function Stepper() {
  const _activeStep = StepperSingleton.getIndex();

  const [activeStep, setActiveStep] = React.useState(_activeStep);

  let store = useStore();

  const handleNext = (step = 1) => {
    if (activeStep === Components.length - 2) {
      store.dispatch({
        type: "checkMaximoConfig",
      });
    }
    step === 1
      ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
      : setActiveStep(step);
  };

  const resetStepper = () => {
    setActiveStep(0);
  };

  const handlePrevious = (step = 1) => {
    step === 1
      ? setActiveStep((prevActiveStep) => prevActiveStep - 1)
      : setActiveStep(step);
  };

  let status = useSelector(
    (state) => state?.entities?.dashboard?.completedInstallation
  );
  const isTransactionServer = useSelector(
    (state) => state?.entities?.dashboard?.isTransactionServer
  );
  const maximoLoginIn = useSelector(
    (state) => state?.entities?.dashboard?.maximoLoginIn
  );

  let isLoading = useSelector((state) => state?.entities?.dashboard?.loading);
  const componentIndex =
    status || (isTransactionServer && maximoLoginIn)
      ? Components.length - 1
      : parseInt(activeStep);
  useEffect(() => {
    getServerConfig()
      .then((response) => {
        const isTransactionServer =
          response?.content?.status === "success" &&
          response?.content?.result?.isTransactionServer
            ? response?.content?.result?.isTransactionServer
            : false;
        store.dispatch({
          type: "dashboard/setIsTransactionServer",
          payload: {
            isTransactionServer,
          },
        });
      })
      .catch((error) => {
        console.error("error=====", error);
      });
  }, []);
  const TargetComponent = Components[componentIndex];

  const RenderComponent = () => {
    return (
      <TargetComponent
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        resetStepper={resetStepper}
      />
    );
  };

  return (
    <div className="installer-container">
      {!isLoading ? (
        <RenderComponent componentIndex={parseInt(activeStep)} />
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}

export default Stepper;
