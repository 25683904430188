import "./PopUpList.scss";

import React from "react";

function PopUpList(props) {
  const { scripts = null } = props;

  return (
    <div className="PopUpList" id="PopUpList" data-testid="PopUpList">
      <ul>
        {scripts?.map((item) => {
          return <li>{item?.name}</li>;
        })}
      </ul>
    </div>
  );
}

export default PopUpList;
