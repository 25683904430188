import "./index.scss";

import React, { useState } from "react";

import List from "@mui/material/List";

import Select from "react-select";

import Listitem from "./list-item/listitem";

import Icon from "../../../../designer/components/icon";

function SystemsBody(props) {
  const { buglist = {} } = props;
  let options = [];
  const [itemselected, setItemselected] = useState(Object.keys(buglist)[0]);

  const HandleOnChange = (event) => {
    setItemselected(event.value);
  };

  Object.keys(buglist)?.map(
    (item, i) => (options = [...options, { value: item, label: item, key: i }])
  );

  return (
    <>
      <div className="p-2 d-flex SystemsBody " data-testid="SystemsBody">
        <Icon size={30} className="icon" color="black" icon="monitoring" />
        <Select
          className="w-25"
          onChange={HandleOnChange}
          options={options}
          placeholder={itemselected}
          defaultValue={itemselected}
          data-testid="AutoscriptsInstall"
        />
      </div>
      <List sx={{ bgcolor: "background.paper" }}>
        {buglist[itemselected]?.map((bug, index) => (
          <div key={index}>
            <Listitem bug={bug} />
          </div>
        ))}
        {!buglist[itemselected] && <p className="p-2">No logs to display</p>}
      </List>
    </>
  );
}

export default SystemsBody;
