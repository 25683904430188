import React from "react";

import PostInstall from "../global-components/post-install/PostInstall";

function AutoscriptPostinstall({ handleNext, handlePrevious }) {
  return (
    <div
      id="AutoscriptPostinstall"
      className="AutoscriptPostinstall"
      data-testid="AutoscriptPostinstall"
    >
      <PostInstall
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        title="MAXapps components are installed successfully"
      />
    </div>
  );
}

export default AutoscriptPostinstall;
