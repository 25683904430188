import "./index.scss";

import React from "react";

import { Card } from "react-bootstrap";

import Icon from "../../../../designer/components/icon";

function InfoCard(props) {
  const { data, icon } = props;
  const color = getInfoColor(data?.type);

  return (
    <div className="info-card p-2">
      <p>{data?.type}</p>
      <Card>
        <Card.Body>
          <Icon size={60} color={color} icon={icon} />
          <div className="px-3 mt-1">
            <h1 style={{ color }}>{data?.number}</h1>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

function getInfoColor(infoType = "") {
  let color = null;

  switch (infoType?.toLowerCase()) {
    case "warnings":
      color = "#f3b14e";
      break;
    case "errors":
      color = "#f34848";
      break;

    default:
      color = "";
      break;
  }

  return color;
}

export default InfoCard;
