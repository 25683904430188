import "./ToggleButton.scss";

import React from "react";

function ToggleButton(props) {
  const { isOn = false, handleToggle = () => {} } = props;

  return (
    <div
      className="toggle-container"
      data-testid="ToggleButton"
      onClick={handleToggle}
    >
      <div className={`dialog-button ${isOn ? "" : "disabled"}`}>
        {isOn ? "ON" : "OFF"}
      </div>
    </div>
  );
}

export default ToggleButton;
