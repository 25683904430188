import { axiosProviderNode } from "../../../../../../http/httpService";
import { services } from "../../../../../../installer/services/configVariables";

async function uploadIconToServer(formData = {}, addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_UPLOAD_ICON;

    const res = await axiosProviderNode
      .post(transactionNodeService, formData)
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          addToast(error?.response?.data?.message?.toString?.(), {
            appearance: "error",
            autoDismissTimeout: 5000,
            autoDismiss: true,
            transitionDuration: 600,
          });
          return;
        }

        addToast(error?.message?.toString?.(), {
          appearance: "error",
          autoDismissTimeout: 5000,
          autoDismiss: true,
          transitionDuration: 600,
        });
      });

    if (res?.data?.success) {
      addToast(res?.data?.message.toString(), {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }

    return res?.data?.content;
  } catch (error) {
    addToast(error?.message?.toString(), {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });
    return error;
  }
}

export default uploadIconToServer;
