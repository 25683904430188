import "./index.scss";

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";

import ConfirmDialog from "../../confirmation-dialog";
import { useToasts } from "react-toast-notifications";
import { FaTimes } from "react-icons/fa";

import handleClickedItem from "../../../../services/helpers/handleClickedItem";
import addNewVersion from "../../../../services/addNewVersion";

function Draft({ lastVersion }) {
  let store = useStore();

  const [showDialog, setShowDialog] = useState(false);

  const confirmQuestion = "Are you sure to discard this draft?";

  const lastVers = store.getState()?.entities?.revision?.lastVersion;

  const CloseDialog = () => setShowDialog(false);
  const OpenDialog = () => setShowDialog(true);
  const { addToast } = useToasts();
  let versionSelected = useSelector((state) =>
    state?.entities?.revision?.itemSelected
      ? state?.entities?.revision.itemSelected
      : {}
  );

  const handlePublish = async () => {
    const { skeleton, maxOrder } = store?.getState()?.entities?.explorer;

    const xmlAlias = inputRef?.current?.value ?? "My Alias";
    const lastVersion = await addNewVersion(
      store,
      skeleton,
      maxOrder,
      xmlAlias,
      addToast
    );

    await handleClickedItem(
      addToast,
      store,
      {},
      lastVersion?.filename,
      false,
      true
    );
  };

  const handleCancel = async () => {
    await handleClickedItem(
      addToast,
      store,
      {},
      lastVersion?.filename,
      false,
      true
    );
  };
  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  useEffect(() => {
    return async () => {
      await handleClickedItem(
        addToast,
        store,
        {},
        lastVersion?.filename,
        false,
        true
      );
    };
  }, []);

  /*************** Functioon to be reused later on  *********************/
  // const getDraftSkeleton = async () => {
  //   const skeleton = store?.getState()?.entities?.explorer?.draftSkeleton;
  //   await handleClickedItem(addToast, store, skeleton, false, true, false);
  // };

  const inputRef = useRef(null);

  return (
    <React.Fragment>
      <div id="Draft" className="Draft">
        <div
          className={
            Object.keys(versionSelected)?.length > 0
              ? "draft-suspendu"
              : "draft-btn"
          }
        >
          <div className="draft-label">
            <span>Alias: </span>
            <input
              className="input-draft"
              ref={inputRef}
              type="text"
              placeholder={"Enter Alias"}
            />
          </div>
          {Object.keys(versionSelected)?.length < 1 && (
            <button className="publish-btn" onClick={handlePublish}>
              Publish
            </button>
          )}
        </div>
        {Object.keys(versionSelected)?.length < 1 && (
          <div className="cancel-draft-btn" onClick={() => OpenDialog()}>
            <FaTimes size={30} color="#4C36A5" />
          </div>
        )}
      </div>
      <ConfirmDialog
        confirmQuestion={confirmQuestion}
        itemName={"Draft of: V" + (lastVers + 1)}
        show={showDialog}
        CloseDialog={CloseDialog}
        ConfirmAction={handleCancel}
      />
    </React.Fragment>
  );
}

export default Draft;
