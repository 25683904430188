import "./index.scss";

import React from "react";

import { Card } from "react-bootstrap";

import Icon from "../../../../designer/components/icon";

function HardWare(props) {
  const {
    name = "",
    value,
    maxvalue,
    icon = "monitoring",
    progressColor,
  } = props;

  let currentValue = maxvalue === 100 ? value : (value / maxvalue) * 100;
  const color = progressColor || getColor(name);

  return (
    <div className="hardware">
      <Card>
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text className="d-flex">
            <span className="px-3 icon">
              <Icon size={26} color="#dddddd" icon={icon} />
            </span>
            <span className=" pourcentage">
              <strong style={{ color }}>
                {value}
                {maxvalue === 100 ? (
                  "%"
                ) : (
                  <span className="go">/{maxvalue} Go</span>
                )}
              </strong>
            </span>
          </Card.Text>
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: `${currentValue}%`, backgroundColor: color }}
              aria-valuenow={currentValue}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

function getColor(name) {
  let color = "";
  switch (name.toLowerCase()) {
    case "cpu":
      color = "#27c677";
      break;
    case "ram":
      color = "#0fc3c9";
      break;
    case "hdd":
      color = "#4c36a5";
      break;

    default:
      color = "grey";
      break;
  }
  return color;
}

export default HardWare;
