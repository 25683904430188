import React, { useRef, useMemo } from "react";

import JoditEditor from "jodit-react";

function EditorComp (props) {
  const { contents, getValue, readOnly } = props;
  const editor = useRef(null);

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={contents}
        config={{
          readonly: readOnly ? readOnly : false,
          showCharsCounter: false,
          showWordsCounter: false,
          showXPathInStatusbar: false,
          toolbar: false,
          askBeforePasteFromWord: false,
          askBeforePasteHTML: false,
          defaultActionOnPaste: "INSERT_CLEAR_HTML",
        }}
        onChange={(content) => getValue(content)}
      />
    ),
    [contents, readOnly]
  );
};

export default EditorComp;
