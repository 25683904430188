import "./index.scss";

import React, { useState } from "react";

import Icon from "../../../designer/components/icon";

import CollectionsBody from "./collections";
import SystemsBody from "./systems";
import Sidebar from "../monitor-sidebar";
import DashboardApp from "../../../dashboard/Dashboard";
function DebugIndex(props) {
  const {
    data,
    collectionsIcon = "monitoring",
    systemsIcon = "monitoring",
  } = props;
  const { collectionData, systembugList } = data?.debug || {};

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <DashboardApp>
      <Sidebar>
        <div className="DebugIndex p-4">
          <div className="bloc-tabs">
            <button
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(1)}
            >
              <Icon
                size={40}
                className="p-2"
                color="#6957b5"
                icon={collectionsIcon}
              />
              Collections
            </button>
            <button
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              <Icon
                size={40}
                className="p-2"
                color="#6957b5"
                icon={systemsIcon}
              />
              Systems
            </button>
          </div>

          <div className="content-tabs">
            <div
              className={
                toggleState === 1 ? "content  active-content" : "content"
              }
            >
              <CollectionsBody collectionData={collectionData} />
            </div>

            <div
              className={
                toggleState === 2 ? "content  active-content" : "content"
              }
            >
              <SystemsBody buglist={systembugList} />
            </div>
          </div>
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export default DebugIndex;
