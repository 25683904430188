import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../http/httpService";

async function editXmlAlias(store, filename, xmlAlias, addToast) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_EDIT_XML_ALIAS;

    const transactionPayload = {
      filename,
      xmlAlias,
    };

    const res = await serviceRequest.post(
      transactionNodeService,
      transactionPayload
    );

    if (res?.data?.success) {
      store.dispatch({
        type: "revision/updateXmlAlias",
        paylaod: {
          objToAdd: res?.data?.result,
        },
      });
    }

    addToast(res?.data?.message, {
      appearance: res?.data?.success ? "success" : "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });

    return res?.data?.content;
  } catch (error) {
    addToast("Server is unreachable!", {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });

    return null;
  }
}

export default editXmlAlias;
