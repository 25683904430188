import getFailedListScripts from "./getFailedListScripts";
import installScriptPart from "./installScriptPart";

async function retryInstallAll(
  failedSciptsList = [],
  setIsVerifying = () => {},
  setEnableRetryButton = () => {},
  setFailedSciptsList = () => {}
) {
  setIsVerifying(true);

  const filtredList = getFailedListScripts(failedSciptsList);

  let responses = [];
  for (let list of filtredList) {
    const result = await installScriptPart(list);
    responses?.push(result);
  }
  const filtredListResponses = getFailedListScripts(responses);
  if (filtredListResponses.length === 0) {
    setEnableRetryButton(false);
  }
  setIsVerifying(false);

  let newList = [];

  if (failedSciptsList?.length !== 0) {
    newList = failedSciptsList?.map((elem) => {
      const filter = responses?.filter((el) => {
        return el?.scriptName === elem?.scriptName;
      });
      if (filter.length) {
        return filter[0];
      }
      return elem;
    });
  }
  setFailedSciptsList(newList);
}

export default retryInstallAll;
