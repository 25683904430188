import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "../../configVariables";

async function getTransactionsData() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_TRANSACTIONS_DATA;

    const res = await axiosProviderNode.get(transactionNodeService);
    return res?.data;
  } catch (error) {
    throw error;
  }
}

export default getTransactionsData;
