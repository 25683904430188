import "./ItemsWrapper.scss";

import React from "react";

import InstallerItem from "./item/InstallerItem";

function ItemsWrapper(props) {
  const { filesList = [], isCurrent = null } = props;

  const filesRenderer = filesList?.map((script, index) => {
    return (
      <InstallerItem
        key={index}
        script={script}
        isCurrent={isCurrent === index}
      />
    );
  });
  return (
    <div id="ItemsWrapper" className="ItemsWrapper" data-testid="ItemsWrapper">
      <div className="scripts-box">{filesRenderer}</div>
    </div>
  );
}

export default ItemsWrapper;
