import "./Designer.scss";

import Header from "./components/header";
import DesignerBody from "./components/designer-body";
import DashboardApp from "../dashboard/Dashboard";

function Designer() {
  return (
    <DashboardApp>
      <div className="App-Designer">
        <Header />
        <DesignerBody />
      </div>
    </DashboardApp>
  );
}

export default Designer;
