import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "../services";

async function getIssuesData() {
  try {
    const transactionNodeService = services?.NODE_ENDPOINT_GET_ISSUES;

    const res = await axiosProviderNode.get(transactionNodeService);
    return res?.data;
  } catch (error) {
    throw error;
  }
}

export default getIssuesData;
