import "./DisconnectScreen.scss";

import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { useStore } from "react-redux";

import AlertDialog from "../global-components/alert-dialog/AlertDialog";
import UninstallProgressBar from "../global-components/uninstall-progress-bar/UninstallProgressBar";
import RetrySync from "./retry-syncbackend/RetrySync";
import ToRetryList from "./to-retry-list/ToRetryList";

import Loading from "../../../../assets/lottie-files/loader.json";
import Lottie from "react-lottie";

import Icon from "../../../designer/components/icon";

import { scriptInstallation } from "../../services/index";
import __getScripts from "./services/__getScripts";
import clearAllFromMaximo from "./services/clearAllFromMaximo";

function DisconnectScreen({ resetStepper, _onPress = () => {} }) {
  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const _isMounted = useRef(true);

  const navigate = useNavigate();

  const [toClear, setToClear] = useState(false);
  const [clearDb, setClearDb] = useState(false);
  const [checkVersions, setcheckVersions] = useState(true);
  const [finish, setFinish] = useState(false);
  const [failedToClear, setFailedToClear] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disconnectMessage, setDisconnectMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [maximoConfig, setMaximoConfig] = useState(null);
  const [response, setResponse] = useState({});

  const clearMessage =
    "Do you want to uninstall MAXapps related configuration from Maximo?";

  const isClearingMessage = "Uninstalling MAXapps";

  let store = useStore();

  const clearOption = "Cleanup database";

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => setShowDialog(true);

  const handleCheckbox = () => {
    setClearDb(!clearDb);
  };

  const checkInstallerTools = async (response, toclearMaximo) => {
    setFailedToClear(false);
    setToClear(true);
    scriptInstallation(response?.content?.script, true)
      .then((res) => {
        if (
          res?.success &&
          res?.content?.status?.toString()?.toLowerCase() !== "failure"
        ) {
          clearAll(toclearMaximo);
        } else {
          const errorMessage =
            res?.content?.message === "" ? res?.message : res?.content?.message;
          setToClear(false);
          setResponse(res);
          setErrorMessage(errorMessage);
          setFailedToClear(true);
        }
      })
      .catch((error) => {
        setToClear(false);
        setErrorMessage(error.message);
        setFailedToClear(true);
      });
  };

  const clearAll = (toclearMaximo) => {
    setFailedToClear(false);
    setToClear(true);
    toclearMaximo
      ? setDisconnectMessage("MAXapps has been uninstalled succesfully")
      : clearDb
      ? setDisconnectMessage("Database has been cleared succesfully")
      : setDisconnectMessage(
          "Maximo Config  has been cleared succesfully from DB"
        );
    clearAllFromMaximo(clearDb, toclearMaximo, checkVersions)
      .then((res) => {
        if (_isMounted.current) {
          if (
            res.success &&
            res?.content?.status?.toString()?.toLowerCase() !== "failure"
          ) {
            localStorage.removeItem("authorization");
            setFinish(true);
            setToClear(false);
          } else {
            setResponse(res);
            setToClear(false);
            const errorMessage =
              res?.content?.message === ""
                ? res?.message
                : res?.content?.message;
            setErrorMessage(errorMessage);
            setFailedToClear(true);
          }
        }
      })
      .catch((error) => {
        console.error("Disconnect error", error);
        setFailedToClear(true);
      });
  };

  const exitInstaller = () => {
    store.dispatch({
      type: "dashboard/updateMaximoLoginStatus",
      payload: {
        status: false,
      },
    });
    store.dispatch({
      type: "checkMaximoConfig",
    });
    navigate("/");
  };

  useEffect(() => {
    __getScripts(setMaximoConfig, _isMounted.current);
    return () => {
      _isMounted.current = false;
    };
  }, [store]);

  return (
    <div
      id="DisconnectScreen"
      className="DisconnectScreen"
      data-testid="DisconnectScreen"
    >
      <div className="disconnect-screen-header">
        <p className="disconnect-screen-title">
          Your MAXapps server is connected to the Maximo instance
        </p>
        {maximoConfig?.maximoUrl ? (
          <React.Fragment>
            <p className="server-url">{maximoConfig?.maximoUrl}</p>
            {maximoConfig?.syncBackendStatus === true ? null : (
              <RetrySync
                setSyncBackendStatus={maximoConfig?.setSyncBackendStatus}
                maxappsHost={maximoConfig?.maxappsHost}
              />
            )}
          </React.Fragment>
        ) : (
          <Lottie
            options={LoadingOptions}
            height={50}
            width={60}
            isClickToPauseDisabled={true}
            style={{
              cursor: "auto",
            }}
          />
        )}
      </div>
      <ToRetryList scripts={maximoConfig?.scripts} />

      <div className="disconnect-footer">
        <button
          data-testid="disconnectButton"
          className="disconnect-button"
          onClick={() => {
            _onPress();
            openDialog();
          }}
        >
          <span className="disconnect-button-text">Disconnect</span>
          <Icon icon="link" size={15} color="#000" />
        </button>
      </div>
      <AlertDialog
        dialogHeader={
          !finish && toClear && !failedToClear
            ? isClearingMessage
            : !finish
            ? clearMessage
            : ""
        }
        show={showDialog}
        CloseDialog={closeDialog}
        stepAction={resetStepper}
      >
        <React.Fragment>
          {!finish && !toClear && !failedToClear && (
            <React.Fragment>
              <div className="dialog-option">
                <input
                  className="dialog-input"
                  type="checkbox"
                  id="topping"
                  name="topping"
                  value={clearDb}
                  onChange={handleCheckbox}
                />
                {clearOption}
              </div>
              <div className="dialog-option" data-testid="checkVersions">
                <input
                  className="dialog-input"
                  type="checkbox"
                  id="checkVersions"
                  name="checkVersions"
                  value={checkVersions}
                  checked={checkVersions}
                  onChange={() => {
                    setcheckVersions(!checkVersions);
                  }}
                />
                check files versions
              </div>
              <div className="confirm-uninstall">
                <button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    closeDialog();
                  }}
                  data-testid="No"
                >
                  <span className="dialog-btn-text">No</span>
                </button>
                <button
                  className="confirm-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearAll(true);
                  }}
                  data-testid="Yes"
                >
                  <span className="dialog-btn-text">Yes</span>
                </button>
              </div>
            </React.Fragment>
          )}
          {!finish && toClear && !failedToClear && (
            <div className="uninstall-progress">
              <UninstallProgressBar
                textColor="#4c36a5"
                bgcolor="#4c36a5"
                completed="50"
              />
            </div>
          )}
          {failedToClear && !toClear && !finish && (
            <div className="retry-uninstall">
              <div>
                <p className="retry-message">{errorMessage}</p>
              </div>
              <div className="retry">
                <button
                  className="retry-btn"
                  onClick={(e) => {
                    if (
                      response &&
                      response?.content?.status?.toString()?.toLowerCase() ===
                        "failure" &&
                      response?.content?.script
                    ) {
                      checkInstallerTools(response, true);
                      return;
                    }
                    clearAll(true);
                  }}
                  data-testid="Yes"
                >
                  <span className="dialog-btn-text">Retry</span>
                </button>
                <button
                  className="retry-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearAll(false);
                  }}
                  data-testid="Yes"
                >
                  <span className="dialog-btn-text">Skip</span>
                </button>
              </div>
            </div>
          )}
          {finish && (
            <div className="success-uninstall">
              <div className="success-title">{disconnectMessage}</div>
              <div className="finish">
                <button
                  className="finish-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    exitInstaller();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      </AlertDialog>
    </div>
  );
}

export default DisconnectScreen;
