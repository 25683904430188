import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "../../configVariables";

async function installDoclinkOS() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_ADD_DOCLINK_OS;

    const body = {};

    const res = await axiosProviderNode.post(transactionNodeService, body);

    return res?.data;
  } catch (error) {
    console.error(error);

    throw error;
  }
}

export default installDoclinkOS;
