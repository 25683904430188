import "./index.scss";

import React from "react";

import { Accordion } from "react-bootstrap";

function Listitem(props) {
  const { collection = {} } = props;

  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <div className="row">
          <div className="col-10">
            <Accordion.Header>{collection.name}</Accordion.Header>
          </div>
          <div className="col-2 span-date">
            <span>{collection.date}</span>
          </div>
        </div>
        <Accordion.Body>
          {collection.subentity ? (
            collection.subentity.map((data, index) => (
              <div key={index}>
                <Listitem collection={data} key={index} />
              </div>
            ))
          ) : (
            <p className="pt-3">{collection.description}</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Listitem;
