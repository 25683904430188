function tryStringifyJSONObject(object = {}) {
  try {
    if (object && typeof object !== "object") {
      return object.toString();
    }
    const stringedValue = JSON.stringify(object);
    return stringedValue;
  } catch (e) {
    return object.toString();
  }
}

export default tryStringifyJSONObject;
