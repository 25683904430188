import { services } from "./configVariable";
import { axiosProviderNode as serviceRequest } from "../../http/httpService";

async function addNewVersion(
  store,
  skeleton,
  maxOrder,
  xmlAlias = "My Alias",
  addToast
) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_ADD_FILE;

    const transactionPayload = {
      content: JSON.stringify(skeleton),
      maxOrder,
      xmlAlias,
    };

    const res = await serviceRequest.post(
      transactionNodeService,
      transactionPayload
    );

    if (res?.data?.success) {
      store.dispatch({
        type: "revision/updateData",
        paylaod: {
          objToAdd: res?.data?.content,
        },
      });
    }

    addToast(res?.data?.message, {
      appearance: res?.data?.success ? "success" : "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });

    return res?.data?.content;
  } catch (error) {
    addToast("Server is unreachable!", {
      appearance: "error",
      autoDismissTimeout: 3000,
      autoDismiss: true,
      transitionDuration: 600,
    });

    return null;
  }
}

export default addNewVersion;
