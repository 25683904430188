import "./index.scss";

import React from "react";

import Icon from "../../../../designer/components/icon";

function IssueFilter(props) {
  const {
    handleSortDate,
    filterIcons = {},
    filterSearch,
    filterSearchByUser,
    filterSearchByDate,
  } = props;

  return (
    <div className=" container-fluid IssueFilter">
      <div className="row">
        <div className="col-1 col-sm-1 col-md-1"></div>
        <div className="col-6 col-sm-6 col-md-6">
          <label htmlFor="formGroupExampleInput">Issue</label>
          <div className="d-flex">
            <input
              name="name"
              type="text"
              placeholder="Search"
              className="form-control w-100"
              onChange={filterSearch}
              id="formGroupExampleInput"
            />
            {/* <Icon color="#6b6096" icon={filterIcons?.issue} /> */}
          </div>
        </div>
        <div className="col-2 col-sm-2 col-md-2">
          <label htmlFor="formGroupExampleInput">User</label>
          <div className="d-flex">
            <input
              name="source"
              type="text"
              placeholder="Search By User"
              className="form-control w-100"
              onChange={filterSearchByUser}
              id="formGroupExampleInput"
            />
            {/* <Icon color="#6b6096" icon={filterIcons?.source} /> */}
          </div>
        </div>
        <div className="col-3 col-sm-3 col-md-3">
          <label htmlFor="formGroupExampleInput ">Date</label>
          <div className="d-flex">
            <input
              name="date"
              type="text"
              placeholder="Search By Date"
              onChange={filterSearchByDate}
              className="form-control w-75"
              id="formGroupExampleInput"
            />
            <button
              type="button"
              onClick={handleSortDate}
              aria-expanded="false"
              id="sortIcon"
            >
              <Icon color="#6b6096" icon={filterIcons?.date} />
            </button>
          </div>
        </div>
        <div className="col-1 col-sm-1 col-md-1"></div>
      </div>
    </div>
  );
}

export default IssueFilter;
