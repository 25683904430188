import React, { useEffect } from "react";

import { ErrorMessage, Field, Form, useFormikContext } from "formik";

import Lottie from "react-lottie";
import Loader from "../../../../../assets/lottie-files/loader.json";

function RenderFormComponent(props) {
  const { maxappsHost = "", isChecking = true } = props;
  const { touched, errors, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("maxappsHost", maxappsHost);
  }, [maxappsHost]);

  const LoadingOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="form-body">
      <Form>
        <div className="sync-form-fields">
          <div className="form-group">
            <Field
              data-testid="url"
              type="text"
              name="maxappsHost"
              placeholder="maxapps host"
              autoComplete="off"
              className={`mt-2 form-control
                  ${
                    touched.maxappsHost && errors.maxappsHost
                      ? "is-invalid"
                      : ""
                  }`}
            />

            <ErrorMessage
              component="div"
              name="maxappsHost"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group">
            <button
              data-testid="ForwardButton"
              disabled={!isChecking}
              className={
                isChecking ? "sync-submit-btn" : "sync-submit-disabled"
              }
            >
              <p>Submit</p>
              {!isChecking && (
                <div className="sync-submit-loader">
                  <Lottie
                    isClickToPauseDisabled={true}
                    options={LoadingOptions}
                    height={30}
                    width={30}
                    style={{
                      cursor: "auto",
                    }}
                  />
                </div>
              )}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default RenderFormComponent;
