import "./SyncForm.scss";

import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import RenderFormComponent from "./renderFormComponent";

import maxappsHostVerification from "./services/maxappsHostVerification";
import fineUrl from "../../maximo-login/standard-login/services/fineUrl";

const LoginSchema = Yup.object().shape({
  maxappsHost: Yup.string().required(
    maxappsHostVerification().then((res) => res)
  ),
});

function SyncForm(props) {
  const [maxappsHost, setMaxappsHost] = useState("");

  useEffect(() => {
    (async () => {
      const res = await maxappsHostVerification();

      setMaxappsHost(res);
    })();
  }, []);

  const { actionSubmit = () => {}, isChecking = true } = props;

  const submitForm = (values) => {
    actionSubmit(values.maxappsHost);
  };

  return (
    <div className="SyncForm" id="SyncForm" data-testid="SyncForm">
      <Formik
        initialValues={{
          maxappsHost: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          values.maxappsHost = fineUrl(values.maxappsHost);
          submitForm(values, setSubmitting);
        }}
      >
        <RenderFormComponent
          maxappsHost={maxappsHost}
          isChecking={isChecking}
        />
      </Formik>
    </div>
  );
}

export default SyncForm;
